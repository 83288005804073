import React from 'react';
import { Link } from "react-router-dom"; 
import usuariogran from "../img/usuariogran.png";
import pollo from "../img/pollo-al-limon.jpeg";
import tortilla from "../img/tortilla-de-setas-y-hortalizas-foto-principal.webp";
import FooterMain from './FooterMain';

const RecetasGuardadas = () => {
  return (
  <div className="contenedor">
    <div className="titulin">
      <Link to={-1}>
        <span className="simbologran">&lt;</span>
      </Link>
      <h2 className="cuerpotexto">Recetas guardadas</h2>      
    </div>
    <div className='flex wrap equiespaciado mb10'>
      <span className="block w20 p10"><img src={usuariogran} alt="usuariogran" /></span>
      <div className='block w80'>
        <h2>Luís María Cabello de los Cobos</h2>
        <span className=''>luismariacc@me.es</span><br /><br />
        <div className='centrado equiespaciado'>
          <button className='bordeblanco'>Editar perfil</button><button className='bordeblanco'>Editar preferencias</button>
        </div>
      </div>
    </div>
    <div className='flex w100 wrap end'>
      <div className='block'><a href='recetas-guardadas-todas'>Ver todas</a></div>
    </div>
        
    <div className="rectangulo round relative fondooscuro">
        <div className='flex wrap izquierda rgap20 vtop'>
            <div className='w40'>
              <img className="w100" src={pollo} />
            </div>
            <div className='w60 pl20'>
              <a href="receta"><h2 className='cuerpotexto'>Pollo al limón</h2></a>
              <a className="w70" href="#"><span className="">por Luis María</span></a>
            </div>
        </div>
    </div>
    <div className="rectangulo round relative fondooscuro">
        <div className='flex wrap izquierda rgap20 vtop'>
            <div className='w40'>
              <img className="w100" src={tortilla} />
            </div>
            <div className='w60 pl20'>
              <a href="receta"><h2 className=' cuerpotexto'>Tortilla de setas</h2></a>
              <a className="w70" href="#"><span className="">por Luis María</span></a>
            </div>
        </div>
    </div>
      <br /><br />
      <FooterMain />
  </div>);
};

export default RecetasGuardadas;