import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Card from './ui/Card.js'
import FoodCategory from "../api/services/FoodCategory.js";
import Recipe from "../api/services/Recipe.js";
import User from "../api/services/User.js";
import FoodCategoryTile from "./ui/FoodCategoryTile.js";
import ReadMoreTile from "./ui/ReadMoreTile.js";
import { ModalContext } from "../contexts/modal.js";
import recipesOfMonthImg from "../img/recipes.jpg"
import UserTile from "./ui/UserTile.js"
import RecipeTile from "./ui/RecipeTile.js";
import RecipeSmallTile from "./ui/RecipeSmallTile.js";
import { PlusIcon } from "@heroicons/react/24/outline";

const Recipes = () => {

  const navigate = useNavigate();

  const [foodCategories, setFoodCategories] = useState([]);
  const [buddyMakerUsers, setBuddyMakerUsers] = useState([]);
  const [recomendedRecipes, setRecomendedRecipes] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [search, setSearch] = useState("");
  const [noResults, setNoResults] = useState(false);

  const modal = useContext(ModalContext);

  useEffect(() => {
    getFoodCategories();
    getBuddyMakerUsers();
    getRecomendedRecipes();
  }, []);

  const getFoodCategories = async () => {
    try {
      const response = await FoodCategory.list();
      setFoodCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const getRecipes = async () => {
    setNoResults(false);
    setRecipes([]);
   
    try {
      const response = await Recipe.list({
        search: search
      });
      if (response.data.length == 0) {
        setNoResults(true);
      }
      setRecipes(response.data);
    } catch (error) {
      console.error('Error fetching recipes:', error);
    }
  };

  const getRecomendedRecipes = async () => {
    try {
      const response = await Recipe.list({
        limit: 4
      });
      setRecomendedRecipes(response.data);
    } catch (error) {
      console.error('Error fetching recomended recipes:', error);
    }
  }

  useEffect(() => {
    const to = setTimeout(() => {
      getRecipes();
    }, 1000);
    return () => clearTimeout(to);
  }, [search]);


  const getBuddyMakerUsers = async () => {
    try {
      const response = await User.listBuddMakers();
      setBuddyMakerUsers(response.data);
    } catch (error) {
      console.error('Error fetching buddymaker users:', error);
    }
  };

  const handleResetSearch = (e) => {
    document.querySelector('[name="search"]').value = '';
    setSearch('');
    setNoResults(false);
  }

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <div className='w-full h-12 flex justify-between space-x-4 text-title items-center text-lg mb-6'>
          <div>
            <div className='w-10 h-10 rounded-full bg-white bg-opacity-20 flex flex-col justify-center items-center'>
                <button onClick={ (e) => { navigate('/', {replace: true}) }} className='text-title w-10 h-10'><FontAwesomeIcon icon={faChevronLeft} /></button>
            </div>
          </div>
          <div className='grow text-center'>
            <div className="w-full h-12 bg-buddy-bg-2 rounded-xl relative shadow-inner ring-inset ring-buddy-bg-3 ring-1">
              <div className="absolute h-12 flex flex-row items-center">
                  <div type='button' className='w-8 ps-4'>
                    <FontAwesomeIcon className='text-buddy-text-3' icon={faSearch} />
                  </div>
              </div>
              <input type="text" onSubmit={(e) => alert("submit")} onChange={ (e) => setSearch(e.target.value) } autoComplete="off" className="appearance-none w-full h-full pb-1 bg-transparent text-white text-sm ps-11 pe-2 placeholder:text-sm rounded-xl" name="search" placeholder="Buscador de recetas" />
              { search.length > 0 &&
                <div className="flex items-center justify-center absolute w-10 h-12 right-0 top-0">
                  <button type='button' onClick={handleResetSearch} className='flex items-center justify-center w-8 me-2 h-full'>
                      <div className='w-8 h-8 rounded-full bg-buddy-bg-2 flex flex-col justify-center items-center'>
                          <FontAwesomeIcon className='text-white' icon={faTimes} />
                      </div>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>

        { search.length > 0 && noResults &&
          <p className="text-buddy-text-1 font-semibold">No se encontraron recetas que cumplan con los criterios de búsqueda.</p>
        }

        { search.length > 0 ?
          <div className="mx-auto w-full">
              <div className="grid grid-cols-1 gap-3 mt-2">
                {
                    recipes.map((recipe, i) => {
                        return (
                          <Card key={i} className="bg-buddy-bg-2" >
                            <RecipeTile recipe={recipe} key={recipe.id} to={ "/recipe?id=" + recipe.id } />
                          </Card>
                        )
                    })
                }
              </div>
          </div>
        :
        <div className="flex flex-col space-y-3">
          <div className="w-full flex justify-between space-x-2 mb-2">
            <div className="bg-buddy-bg-3 rounded-lg text-sm font-semibold text-buddy-text-1 py-2 px-3">Ingrendientes</div>
            <div className="bg-buddy-bg-3 rounded-lg text-sm font-semibold text-buddy-text-1 py-2 px-3">Tipo de comida</div>
            <div className="bg-buddy-bg-3 rounded-lg text-sm font-semibold text-buddy-text-1 py-2 px-3">Calorías</div>
          </div>
          <div className="flex relative items-center w-full bg-opacity-80 rounded-2xl bg-black h-28 overflow-hidden" >
            <img src={recipesOfMonthImg} className="w-full aspect-1 opacity-60 rounded-2xl cent" />
            <div className="w-full text-center absolute text-buddy-text-1 font-semibold">Recetas del mes</div>
          </div>
          <Card className="bg-buddy-bg-2" >
            <h2 className="text-buddy-text-1 text-center font-semibold text-lg">BuddyMakers</h2>
            <div className="mb-0">
                <div className="mx-auto w-full">
                    <div className="flex flex-no-wrap overflow-x-auto scrolling-touch mt-4">
                      {
                          buddyMakerUsers.map(user => {
                              return (
                                <UserTile user={user} key={user.id} to={ "/recipesSearch?user_id=" + user.id } />
                              )
                          })
                      }
                      {
                        <ReadMoreTile to="/buddyMakerList" />
                      }
                    </div>
                </div>
            </div>
          </Card> 

          <Card className="bg-buddy-bg-2">
            <h2 className="text-buddy-text-1 text-center font-semibold text-lg">Categorías principales</h2>
            <div className="mb-0">
                <div className="mx-auto w-full">
                    <div className="flex flex-no-wrap overflow-x-auto scrolling-touch mt-4">
                      {
                          foodCategories.map(foodCategory => {
                              return (
                                <FoodCategoryTile foodCategory={foodCategory} key={foodCategory.id} to={ "/recipesSearch?food_category_id=" + foodCategory.id } />
                              )
                          })
                      }
                    </div>
                </div>
            </div>
          </Card> 

          <Card className="bg-buddy-bg-2">
            <h2 className="text-buddy-text-1 text-center font-semibold text-lg">En base a tus preferencias</h2>
            <div className="mb-0">
                <div className="flex w-full">
                    <div className="w-3/4 grid grid-cols-2 gap-3 mt-4">
                      {
                          recomendedRecipes.map(recipe => {
                              return (
                                <RecipeSmallTile recipe={recipe} key={recipe.id} to={ "/recipe?id=" + recipe.id } />
                              )
                          })
                      }
                    </div>
                    <div className="flex justify-end w-1/4">
                    <Link to={"/recomendedRecipes"} className="flex items-center justify-center rounded-lg">
                      <div className="flex items-center justify-center w-12 h-12 rounded p-2 bg-buddy-bg-3">
                          <PlusIcon className='size-6 text-primary grow-0' />
                      </div>
                      </Link>
                    </div>
                </div>
            </div>
          </Card> 
        </div>
      }
      </div>
    </div>
  );
};

export default Recipes;
