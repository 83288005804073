import React from 'react'
import { Link } from 'react-router-dom'

const ConditionsCheckbox = ({ name }) => {


    return (
        <>
            <div className="flex gap-2 text-white">
                <input name={name} type="checkbox" className="shrink-0 rounded-sm border-2 w-5 h-5 mt-1 checked:accent-primary focus:ring-0 focus:shadow " />
                <div className="">
                    <label className="block text-sm">
                        He leído y acepto los <Link className='text-primary' to=''>Términos y Condiciones</Link> y la  <Link className='text-primary' to=''>Política de Privacidad</Link> de BuddyMarket.
                    </label>
                </div>
            </div>
        </>
    )
}
export default ConditionsCheckbox 