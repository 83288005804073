import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useState } from 'react';
import buddysus from '../img/buddysus.png'; 

const P400 = () => {

  const navigate = useNavigate();

  return (
    <div className="contenedor">
      <div className="rectangulo centrado h60">
        
      </div>
      <div className="rectangulo centrado fondouno round">
      <img className="gracias" src={buddysus} alt="No encontrado" />
      </div>
      <div className="rectangulo centrado round mb20">
        <h1 className="naranja">¡No lo encuentro!</h1>
        <p>He intentado mostrarte lo que estás buscando pero no lo he podido encontrar.</p>
      </div>
      <div className="w100">
         <Link to={-1}>
          <button className="w100 submit fondonaranja" type="button">
            Volver
          </button>
        </Link>
        
      </div>
    </div>
  );
};

export default P400;