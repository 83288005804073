import React, { useEffect, useState } from 'react';

function RecetaIngr({idreceta}){

  const [ingredients, setIngredients] = useState([]);
  const placeholderIcono = "carrito-compra.png";
  
  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/recipes/readingredients.php?id=${idreceta}`)
      .then(response => response.json())
      .then(data => {
        setIngredients(data.data);
      })
      .catch(error => console.error('Error fetching buddymaker:', error));
  }, []);

  return (
    <div>
      {ingredients?.map((ingredient) => (
        <div key={ingredient.id} className="flex w100">
          {ingredient.tipo == 1 ? (
            <h3>{ingredient.cadena}</h3>
          ) : (
            <div className='flex vcentrado gap10 rgap20'>
              <img
              className="iconocomida"
              src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/iconos/${
                ingredient.icono || placeholderIcono
              }`}
              alt={ingredient.cadena}
            /><strong>{ingredient.cantidad} {ingredient.medida}</strong> {ingredient.cadena}</div>
          )}
        </div>
      ))}
    </div>
  );

};
export default RecetaIngr;