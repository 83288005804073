import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import User from "../api/services/User.js";
import UserCard from './ui/UserCard.js';


const BuddyMakerList = () => {

  const navigate = useNavigate();

  const [buddyMakerUsers, setBuddyMakerUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    getBuddyMakerUsers();
  }, []);


  useEffect(() => {
    const to = setTimeout(() => {
      getBuddyMakerUsers();
    }, 500);
    return () => clearTimeout(to);
  }, [search]);


  const getBuddyMakerUsers = async () => {
    try {
      const response = await User.list({
        search: search,
        role_id: 5
      });
      setBuddyMakerUsers(response.data);
    } catch (error) {
      console.error('Error fetching buddymaker users:', error);
    }
  };

  const handleResetSearch = (e) => {
    document.querySelector('[name="search"]').value = '';
    setSearch('');
    setNoResults(false);
  }

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <div className='w-full h-12 flex justify-between space-x-4 text-title items-center text-lg mb-6'>
          <div>
            <div className='w-10 h-10 rounded-full bg-white bg-opacity-20 flex flex-col justify-center items-center'>
                <Link to={-1} className='text-title'><FontAwesomeIcon icon={faChevronLeft} /></Link>
            </div>
          </div>
          <div className='grow text-center'>
            <div className="w-full h-12 bg-buddy-bg-2 rounded-xl relative shadow-inner ring-inset ring-buddy-bg-3 ring-1">
              <div className="absolute h-12 flex flex-row items-center">
                  <div type='button' className='w-8 ps-4'>
                    <FontAwesomeIcon className='text-buddy-text-3' icon={faSearch} />
                  </div>
              </div>
              <input type="text" onSubmit={(e) => alert("submit")} onChange={ (e) => setSearch(e.target.value) } autoComplete="off" autoFocus="off" className="appearance-none w-full h-full pb-1 bg-transparent text-white text-sm ps-11 pe-2 placeholder:text-sm rounded-xl" name="search" placeholder="Buscar" />
              { search.length > 0 &&
                <div className="flex items-center justify-center absolute w-10 h-12 right-0 top-0">
                  <button type='button' onClick={handleResetSearch} className='flex items-center justify-center w-8 me-2 h-full'>
                      <div className='w-8 h-8 rounded-full bg-buddy-bg-2 flex flex-col justify-center items-center'>
                          <FontAwesomeIcon className='text-white' icon={faTimes} />
                      </div>
                  </button>
                </div>
              }
            </div>
          </div>
        </div>

        { search.length > 0 && noResults &&
          <p className="text-buddy-text-1 font-semibold">No se encontraron recetas que cumplan con los criterios de búsqueda.</p>
        }

        <div className="flex flex-col space-y-4">
        {
          buddyMakerUsers.map((user, i) => {
              return (
                <UserCard user={user} key={user.id} to={ "/recipesSearch?user_id=" + user.id } />
              )
          })
        }
        </div>
      </div>
    </div>
  );
};

export default BuddyMakerList;
