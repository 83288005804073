import React from 'react';
import { useState, useEffect  } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import FooterMain from './FooterMain';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { faShareNodes } from '@fortawesome/free-solid-svg-icons';
import nutriscore from "../img/nutriscore.png";
import RecetaIngr from "./RecetaIngr";
import RecetaInstr from "./RecetaInstr";
import { isBuddyMaker, getUserInfo } from "../auth";


const Receta = () => {

  const [receta, setReceta] = useState([]);
  const [buddymaker, setBuddymaker] = useState([]);
  const { id } = useParams();
  const userinfo = getUserInfo();
  const [capaVisible, setCapaVisible] = useState("capa1");
  const [url, setUrl] = useState("");
  const [favorito, setFavorito] = useState(0);
  const idreceta = id;
  const iduser = userinfo.id;
  const [totalFavoritos, setTotalFavoritos] = useState(1);

  useEffect(() => {
    const currentUrl = window.location.href;
    setUrl(currentUrl);
  }, []);

  
  const handleButtonClick = (e,capa) => {
    e.preventDefault();
    setCapaVisible(capa);
  };

  async function handleFavoriteClick(e,id,iduser,favorito){
    e.preventDefault();
    
    if(favorito == 0){
      return fetch(`https://buddymarket.io/dummy/apibm/recipes/addfavorite.php/?id=${idreceta}&u=${iduser}`,{
        method: 'GET',
      })
      .then(response => response.json())
      .then(data =>{
        setFavorito(data.data[0].fav);
      })
      .catch(error => console.error('Error updating favorite:', error));
    } else {
      return fetch(`https://buddymarket.io/dummy/apibm/recipes/quitfavorite.php/?id=${idreceta}&u=${iduser}`,{
        method: 'GET',
      })
      .then(response => response.json())
      .then(data =>{
        setFavorito(data.data[0].fav);
      })
      .catch(error => console.error('Error updating favorite:', error));
    }
  }

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/users/readbuddyrec.php?id=${idreceta}`)
      .then(response => response.json())
      .then(data => {
        setBuddymaker(data.data);
      })
      .catch(error => console.error('Error fetching buddymaker:', error));
  }, []);

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/recipes/read.php/?id=${idreceta}`)
      .then(response => response.json())
      .then(data => {
        setReceta(data.data);
      })
      .catch(error => console.error('Error fetching recipe:', error));
  }, []);

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/recipes/favorite.php/?id=${idreceta}&u=${iduser}`)
      .then(response => response.json())
      .then(data => {
        setFavorito(data.data[0].fav);
      })
      .catch(error => console.error('Error fetching recipe:', error));
  }, [handleFavoriteClick]);

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/recipes/totfavorite.php/?id=${idreceta}`)
      .then(response => response.json())
      .then(data => {
        setTotalFavoritos(data.data[0].favs);
      })
      .catch(error => console.error('Error fetching recipe:', error));
  }, [handleFavoriteClick]);


  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Receta</h2>      
      </div>
      
      <div className="rectangulo round relative fondouno">
        
        <div className='flex nowrap derecha vcentrado buddys'>
          {buddymaker.map(buddym => (
            <div key={buddym.id}>
              <a className="block derecha" href={`../buddymaker/${buddym.id}`}>
                <img width="64" src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/users/${buddym.foto}`} /><span className="minititulo">{buddym.username}</span>
              </a>      
            </div> 
          ))}
        </div>  
          <br />    
        {receta.map(rec => (  
        <div key={rec.id}>
          <h2 className="mb20">{rec.name}</h2>
          <img className="w100 round mb20" src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${rec.photo}`} alt={rec.name} /><br />
          <div className='w100 mb20'>
            {rec.description}
          </div>
          <div className="w100">
            <span className="w80 inlineblock izquierda">
              <span className='naranja grande'>
                <a href="#" onClick={(e) => {handleFavoriteClick(e,rec.id,iduser,favorito)}}>
                  {(favorito == 0)?<svg width="24px" className="favorite-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M336 0H48C21.5 0 0 21.5 0 48v464l192-112 192 112V48c0-26.5-21.5-48-48-48zm0 428.4l-144-84-144 84V54a6 6 0 0 1 6-6h276c3.3 0 6 2.7 6 6V428.4z"/></svg>:null}
                  {(favorito != 0)?<FontAwesomeIcon icon={faBookmark} />:null}      
                </a>
              </span>

              &nbsp;&nbsp;
              <span className='pequeno'> <span className='naranja f14'> {totalFavoritos}</span>&nbsp; Personas guardaron esto</span>
            </span>
            <span className="w20 inlineblock derecha">
              <Link to="/compartir/" state={{ url }}><span className='naranja f20'><FontAwesomeIcon icon={faShareNodes} /></span></Link>
            </span>
          </div>
        </div>
        ))}  
      </div>  

      <div className="rectangulo round relative fondouno">
        <div className='flex nowrap equiespaciado centrado'>
          <div className={capaVisible === "capa1" ? "w30 ladoizqnaranja" : "w30"}>
            <a href="#" onClick={(e) => handleButtonClick(e,"capa1")}><span className="naranja">2</span><br /><span className="textoblanco pequeno">Ingredientes</span></a>
          </div>
          <div className={capaVisible === "capa2" ? "w30 ladoizqnaranja" : "w30"}>
            <a href="#" onClick={(e) => handleButtonClick(e,"capa2")}><span className="naranja">1h6min</span><br /><span className="textoblanco pequeno">Instrucciones</span></a>
          </div>
          <div className={capaVisible === "capa3" ? "w30 ladoizqnaranja" : "w30"}>
            <a href="#" onClick={(e) => handleButtonClick(e,"capa3")}><img src={nutriscore} alt="nutriscore" /><br /><span className="textoblanco pequeno">Valores nutricionales</span></a>
          </div>
        </div>
        {receta.map(rec => (
        <div key={rec.id} id="contenidos capas">
          <div className='botoneraraciones vcentrado gap20 flex'>
            <span className="cuadradillo centrado" id="menosracion"> - </span> <span id="numraciones"> 2 raciones </span> <span className="cuadradillo centrado" id="masracion"> + </span> 
          </div>
          <div id="capa1" className={capaVisible === "capa1" ? "visible cuadrado " : "oculto cuadrado "}>
            <RecetaIngr 
              idreceta={rec.id}
            />
          </div>
          <div id="capa2" className={capaVisible === "capa2" ? "visible cuadrado " : "oculto cuadrado "}>
           <RecetaInstr 
              idreceta={rec.id}
            />
          </div>
          <div id="capa3" className={capaVisible === "capa3" ? "visible cuadrado " : "oculto cuadrado "}>
            <h2>Información nutricional</h2>
            <div className='tablanutricional w100 flex wrap'>
              <div className='w100 flex spacebetween'><span className="w70">Elemento</span><span className="w15">Cantidad</span><span className="w15">CDR</span></div>
              <div className='w100 flex spacebetween'><span className="w70">Proteínas:</span><span className="w15">0</span><span className="w15">0</span></div>
              <div className='w100 flex spacebetween'><span className="w70">Hidratos de carbono:</span><span className="w15">0</span><span className="w15">0</span></div>
              <div className='w100 flex spacebetween'><span className="w70">Grasas:</span><span className="w15">0</span><span className="w15">0</span></div>
              <div className='w100 flex spacebetween'><span className="w70">Fibra:</span><span className="w15">0</span><span className="w15">0</span></div>
              <div className='w100 flex spacebetween'><span className="w70">Sal:</span><span className="w15">0</span><span className="w15">0</span></div>
            </div>
          </div>
        </div>
        ))}
      </div>
      <br /><br />
      <FooterMain />
    </div>
    
  );
};

export default Receta;