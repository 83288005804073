import React from "react";
import { useState } from "react";
import { Link } from 'react-router-dom';
import gracias from "../img/gracias.png";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const Suscripcion = () => {
  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Suscripciones</h2>
      </div>
      <div className="rectangulo centrado h60"></div>

      <div className=" rectangulo round mb20 fondodos">
        <h1 className="auto">
          Plan trimestral <span className="derecha f09"><span className="naranja">18.99€</span><span className="normal">/Trimestre</span></span>
        </h1>
        <hr />
        <p><FontAwesomeIcon icon={faCheck} className="f20 mr10 textoverde" /> Experiencia sin publicidad</p>
        <p><FontAwesomeIcon icon={faCheck} className="f20 mr10 textoverde" /> Soporte 24/7</p>
      </div>

      <div className=" rectangulo round mb20 fondodos">
        <h1 className="auto">
          Agregar tarjeta de pago</h1>
        <hr />
        <label className="izquierda w100 mb10" htmlFor="numtarjeta">Número de tarjeta</label><br />
        <div className="input-container"> 
          <input className="w100 mb20" type="text" name="numtarjeta" placeholder="Ej: 3215 1321 3513 8657" /><br />
        </div>
        <div className="flex equiespaciado gap20">
          <div>
            <label className="izquierda w100 mb10" htmlFor="cvv">CVV</label><br />
            <div className="input-container"> 
              <input className="w100 mb20" type="text" name="cvv" placeholder="Ej: 247" /><br />
            </div>
          </div>
          <div>
            <label className="izquierda w100 mb10" htmlFor="fechaexp">Fecha expiración</label><br />
            <div className="input-container"> 
              <input className="w100 mb20" type="text" name="fechaexp" placeholder="Ej: 12/35" /><br />
            </div>
          </div>
        </div>
      </div>
      
      <div className=" rectangulo round mb20 fondodos">
        <h2 className="centrado">
          ¿Tienes un código descuento?</h2>
          <div className="flex equiespaciado gap20">
          <div>
            <label className="izquierda w100 mb10" htmlFor="cvv">Código promocional</label><br />
            <div className="input-container"> 
              <input className="w80 mb20" type="text" name="cvv" placeholder="Ej: 247" /><br />
              <button className="round fondonaranja textoblanco w80">Validar Código</button>
            </div>
          </div>
          <div className="w100 ">
            <br />
            <div className="w20">
              <FontAwesomeIcon icon={faCheck} />
            </div>
            <div className="w80">
              <p className="textoblanco">¡El código MARIA-25 se añadió con éxito!</p>
              <p className="textoblanco f07">este código de proporciona un descuento de un 20% en tu suscripción</p>
            </div>
          </div>
        </div>
      </div>

      <form action="suscripcionfin">
        <div className="">
          <button className="w100 submit fondonaranja" type="submit">
            Pagar
          </button>
        </div>
      </form>
    </div>
  );
};

export default Suscripcion;
