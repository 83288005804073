import React, { useState } from 'react'

const PrimaryButton = ({ size = 'md', children, ...attributes }) => {

    const sizeClasses = size == 'md' ? 'w-full p-3 rounded-xl text-lg' : 'py-2 px-4 rounded-lg';
    const classes = 'bg-primary-button-bg bg-opacity-40  text-primary font-semibold  ' + sizeClasses; 

    return (
        <>
            <button className={classes}
                {...attributes}>
                    {children}
            </button>
        </>
    )
}
export default PrimaryButton 