import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Allergy from '../api/services/Allergy';
import CheckBoxSelect from './forms/CheckBoxSelect';

const ProfileForm8 = () => {
  const navigate = useNavigate();

  const [allergiesIds, setAllergiesIds] = useState([]);
  const [allergiesOptions, setAllergiesOptions] = useState([]);

  const handleClick = (e) => {
    const data = JSON.parse(localStorage.getItem('register_data'));
    data.allergies_ids = allergiesIds;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm9');
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setAllergiesIds(data.allergies_ids ?? []);
    }
    loadAllergies();
  }, []);

  const onSelectionChange = (selection) => {
    setAllergiesIds(selection);
  }

  async function loadAllergies() {
    try {
      const response = await Allergy.list();
      var options = [];
      response.data.map(allergy => {
        options.push({
          value: allergy.id,
          label: allergy.name
        });
      });
      setAllergiesOptions(options);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={-1}>
              <div className='text-sm'>
                <span >Paso 8</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>¿Tienes alergias o no te gusta algún alimento?</h2>
            <CheckBoxSelect name="allergies_ids" onChange={onSelectionChange} selection={allergiesIds} options={allergiesOptions} />

            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        
      </div>
    </div>
  );
};

export default ProfileForm8;