import React, { useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const Input = ({ value, name, label = null, type = 'text', placeholder='', helpText = '', onChange = null, onReset = null}) => {

    const [inputValue, setInputValue] = useState('');
    const inputReference = useRef(null);

    const resetButtonClassName = inputValue.length > 0 ? 'absolute right-0 bottom-0 top-0' : 'hidden absolute right-0 bottom-0 top-0';

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        if (onChange) {
            onChange(e);
        }
    }

    const handleResetInput = () => {
        setInputValue('');
        if (onReset) {
            onReset();
        }
        inputReference.current.focus();
    }

    return (
        <>
            <div className='w-full'>
                {
                    label != null ? <p className="text-white font-semibold text-md mb-2" htmlFor="email">{label}</p> : ''
                }
                
                <div className="w-full h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
                    <div className={resetButtonClassName}>
                        <button type='button' onClick={handleResetInput} className='flex items-center justify-center w-8 me-2 h-full'>
                            <div className='w-8 h-8 rounded-full bg-form-button-bg flex flex-col justify-center items-center'>
                                <FontAwesomeIcon className='text-white' icon={faTimes} />
                            </div>
                        </button>
                    </div>
                    <input defaultValue={inputValue} onChange={handleInputChange} ref={inputReference} autoComplete="new-password" aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-white ps-4 pe-16 placeholder:font-light rounded-xl"
                        name={name}
                        type={type}
                        placeholder={placeholder}
                    />
                </div>
                <span className='text-body text-xs'>{helpText}</span>
            </div>
        </>
    )
}

export default Input 