import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Recipe from "../api/services/Recipe.js";
import RecipeTile from "./ui/RecipeTile.js";
import Card from "./ui/Card.js";
import { useSearchParams } from "react-router-dom";
import User from "../api/services/User.js";
import FoodCategory from "../api/services/FoodCategory.js";
import SmallToggle from "./forms/SmallToggle.js";
import Toolbar from "./ui/Toolbar.js";

const RecipeList = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [recipes, setRecipes] = useState([]);
  const [search, setSearch] = useState("");

  const user = JSON.parse(localStorage.getItem('user'));
  //console.log(user);

  const [searchMode, setSearchMode] = useState(1);
  
  const searchModeOptions = [
    {
      value: 1,
      label: "Favoritas"
    },
    {
      value: 2,
      label: "Creadas por mí"
    }
  ]

  useEffect(() => {    

  }, []);

  const getRecipes = async () => {
    setRecipes([]);
    if (searchMode == 1) {
      try {
        const response = await User.favoriteRecipesList(user.id);
        setRecipes(response.data);
      } catch (error) {
        console.error('Error fetching recipes:', error);
      }
    } else {
      try {
        const response = await User.recipeList(user.id);
        setRecipes(response.data);
      } catch (error) {
        console.error('Error fetching recipes:', error);
      }
    }
    
  };

  useEffect(() => {
    const to = setTimeout(() => {
      getRecipes();
    }, 500);
    return () => clearTimeout(to);
  }, [search, searchMode]);

  const handleOnChangeSearchMode = (value) => {
    setRecipes([]);
    setSearchMode(value);
  }

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <Toolbar to={-1}>
          <div className='text-md'>
            <span>Mis recetas</span>
          </div>
        </Toolbar>

        {
          user.role.id == 5 ? <SmallToggle selected={searchMode}  onChange={handleOnChangeSearchMode} options={searchModeOptions} /> : ''
        }

        <div className="mx-auto w-full mt-4">
            <div className="grid grid-cols-1 gap-3 mt-2">
              {
                  recipes.map(recipe => {

                      console.log(recipe)
                      console.log(user.id)

                      return (
                        <Card className="bg-buddy-bg-2" key={recipe.id}>
                          <RecipeTile showStatus={searchMode == 2} showAuthor={searchMode == 1} to={ recipe.user_id === user.id ? ("/recipeEdit?id=" + recipe.id) : ("/recipe?id=" + recipe.id) } recipe={recipe} key={recipe.id} />
                        </Card>
                      )
                  })
              }
            </div>
        </div>
      </div>
    </div>
  );
};

export default RecipeList;
