import React, { useContext } from "react";
import { useState, useEffect } from "react";
import {useNavigate } from 'react-router-dom';
import FoodCategory from "../api/services/FoodCategory.js";
import Recipe from "../api/services/Recipe.js";
import Toolbar from "./ui/Toolbar.js";
import Input from "./forms/Input.js";
import Textarea from "./forms/Textarea.js";
import Measure from "../api/services/Measure.js";
import User from "../api/services/User.js";
import DietRestriction from "../api/services/DietRestriction.js";
import Select from "./forms/Select.js";
import SmallToggle from "./forms/SmallToggle.js";
import FooterToolbar from "./ui/FooterToolbar.js";
import PrimaryButton from "./ui/PrimaryButton.js";
import { CameraIcon, CheckIcon, PlusIcon, ReceiptPercentIcon, } from "@heroicons/react/24/solid";
import { MagnifyingGlassIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import SelectRounded from "./forms/SelectRounded.js";
import SecondaryButton from "./ui/SecondaryButton.js";
import { useSearchParams } from "react-router-dom";
import SecondaryOutLineButton from "./ui/SecondaryOutlineButton.js";

const RecipeCreate = () => {

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const [foodCategoryId, setFoodCategoryId] = useState('');
  const [foodCategoryOptions, setFoodCategoryOptions] = useState([]);
  const [status, setStatus] = useState(0);
  const [description, setDescription] = useState('');

  const [imageFile, setImageFile] = useState(null);
  const [imageData, setImageData] = useState(null);

  const [ingredients, setIngredients] = useState([]);
  const [ingredientSearch, setIngredientSearch] = useState('');
  const [ingredientSearchEnabled, setIngredientSearchEnabled] = useState(true);
  const [ingredient, setIngredient] = useState(null);
  const [recipeIngredients, setRecipeIngredients] = useState([]);
  const [showAddIngredientForm, setShowAddIngredientForm] = useState(false);
  const [showEditIngredientForm, setShowEditIngredientForm] = useState(false);
  const [editIngredientIndex, setEditIngredientIndex] = useState(null);

  const [tags, setTags] = useState([]);
  const [tagSearch, setTagSearch] = useState('');
  const [recipeTags, setRecipeTags] = useState([]);

  const [recipeSteps, setRecipeSteps] = useState([]);
  const [recipeStep, setRecipeStep] = useState('');
  const [showAddStepForm, setShowAddStepForm] = useState(false);
  const [showEditStepForm, setShowEditStepForm] = useState(false);
  const [editStepIndex, setEditStepIndex] = useState(null);
  
  
  const [measureId, setMeasureId] = useState(null);
  const [measureOptions, setMeasureOptions] = useState([]);

  

  const user = JSON.parse(localStorage.getItem('user'));
  const id = searchParams.get('id') ?? '';

  const statusOptions = [
    {
      value: 1,
      label: "Publicada"
    },
    {
      value: 0,
      label: "Borrador"
    }
  ]

  useEffect(() => {    
    getRecipe(id);
    getFoodCategories();
    getMeasures();
    getImage();
  }, []);

  const getRecipe = async (id) => {
    try {
      const response = await User.recipeGet(user.id, id);
      const recipe = response.data;
      document.querySelector('[name="name"]').value = recipe?.name;
      document.querySelector('[name="description"]').value = recipe?.description;
      document.querySelector('[name="preparation_time"]').value = recipe?.preparation_time;
      document.querySelector('[name="cook_time"]').value = recipe?.cook_time;
      document.querySelector('[name="rations"]').value = recipe?.rations;
      document.querySelector('[name="food_category_id"]').value = recipe?.food_category_id;
      setFoodCategoryId(recipe?.food_category_id);
      setStatus(recipe?.active ? 1 : 0);
      setDescription(recipe?.description);
      setRecipeSteps(recipe?.recipeSteps);
      setRecipeTags(recipe?.dietRestrictions);
      var recipeIngredients = [];
      recipe?.recipeIngredients.map(recipeIngredient => {
        recipeIngredients.push({
          id: recipeIngredient.id,
          quantity: recipeIngredient.quantity,
          measure_id: recipeIngredient.measure.id,
          label: recipeIngredient.ingredient.name,
          image_url: recipeIngredient.ingredient.image_url,
          measure_label: recipeIngredient.measure.name,
        });
      });
      setRecipeIngredients(recipeIngredients);
    } catch (error) {
      console.error('Error fetching recipe:', error);
    }
  };

  const getImage = async () => {
    try {
        const response = await Recipe.image(id);
        const blob = await response.blob();
        setImageData(URL.createObjectURL(blob));
    } catch (error) {
        console.error('Error fetching recipe image:', error);
    }
  }

  const handleUpdate = async () => {

    try {
      var fileInput = document.querySelector('input[type="file"]')
      const body = new FormData();
      body.append('name', document.querySelector('[name="name"]').value);
      if (fileInput.files[0] !== undefined) {
        body.append('image_file', fileInput.files[0]);
      }
      body.append('description', description);
      body.append('preparation_time', parseInt(document.querySelector('[name="preparation_time"]').value));
      body.append('cook_time', parseInt(document.querySelector('[name="cook_time"]').value));
      body.append('rations', parseInt(document.querySelector('[name="rations"]').value));
      body.append('food_category_id', foodCategoryId);
      body.append('ingredients', JSON.stringify(recipeIngredients));
      body.append('steps', JSON.stringify(recipeSteps));
      body.append('diet_restriction_ids', recipeTags.map(t => t.id).join(','));
      body.append('active', status);
      await Recipe.update(id, body);
      alert("Receta actualizada correctamente");
      getRecipe(id);
    } catch (error) {
      alert(error.response.payload.message ?? 'Error inesperado. Inténtalo de nuevo.');
      console.error('Error creating recipe:', error);
    }
  }

  const getFoodCategories = async () => {
    try {
      const response = await FoodCategory.list();
      var options = [];
      response.data.map(type => {
        options.push({
          value: type.id,
          label: type.name
        });
      });
      setFoodCategoryOptions(options);
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  };

  const getMeasures = async () => {
    try {
      const response = await Measure.list();
      var options = [];
      response.data.map(type => {
        options.push({
          value: type.id,
          label: type.name
        });
      });
      setMeasureOptions(options);
      setMeasureId(options[0].value);
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  };

  const getIngredients = async (search) => {
    try {
      fetch('https://api.edamam.com/auto-complete?app_id=faa25301&app_key=70ce3d337dc0bed64e71464986c159df&q=' + ingredientSearch)
      .then(response => response.json())
      .then(data => {
        setIngredients(data);
      });
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  }

  const getTags = async (search) => {
    try {
      const response = await DietRestriction.list({
        search: search,
        limit: 10
      });
      setTags(response.data); 
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  }

  const getIngredient = async (label) => {
    try {
      fetch('https://api.edamam.com/api/food-database/v2/parser?app_id=faa25301&app_key=70ce3d337dc0bed64e71464986c159df&ingr=' + label)
      .then(response => response.json())
      .then(data => {
        if (data.parsed.length > 0) {
          setIngredient(data.parsed[0].food);
          document.querySelector('[name="ingredient_search"]').value = data.parsed[0].food.label;
          setIngredientSearch(data.parsed[0].food.label);
        }
      });
    } catch (error) {
      console.error('Error fetching FoodCategory:', error);
    }
  }

  const addIngredient = () => {

    if (document.querySelector('[name="quantity"]').value == '') {
      alert("Es necesario que indiques la cantidad");
    } else {
      var currentIngredients = recipeIngredients.slice();
      const measure = measureOptions.find((option) => option.value == measureId);
      currentIngredients.push({
        ingredient_api_data: ingredient,
        quantity: document.querySelector('[name="quantity"]').value,
        measure_id: measureId,
        label: ingredient.label,
        image_url: ingredient.image,
        measure_label: measure.label,
      });
      setRecipeIngredients(currentIngredients);
      handleResetIngredient();
      setShowAddIngredientForm(false);
      document.querySelector('[name="quantity"]').value = '';
    }
  }

  const addTag = (tag) => {
    var current = recipeTags.slice();
      current.push(tag);
      setRecipeTags(current);
      setTags([]);
      setTagSearch('');
      document.querySelector('[name="tag_search"]').value = '';
  }

  const addStep = () => {
    var currentSteps = recipeSteps.slice();
      currentSteps.push({
        description: document.querySelector('[name="recipe_step"]').value,
      });
      setRecipeSteps(currentSteps);
      setRecipeStep('');
      setShowAddStepForm(false);
      document.querySelector('[name="recipe_step"]').value = '';
  }

  const handleOnChangeIngredientSearch = (e) => {
    const search = e.target.value;
    if (search.length < 3) {
      setIngredients([]);
    } else {
      getIngredients(e.target.value);
    }
    setIngredientSearch(search);
  }

  const handleOnChangeTagSearch = (e) => {
    const search = e.target.value;
    if (search.length == 0) {
      setTags([]);
    } else {
      getTags(e.target.value);
    }
    setTagSearch(search);
  }

  const handleOnFocusIngredientSearch = (e) => {
    if (ingredientSearchEnabled) {
      const ingredientSearch = e.target.value;
      if (ingredientSearch.length < 3) {
        setIngredients([]);
      } else {
        getIngredients(e.target.value);
      }
    }
  }

  const handleOnFocusTagSearch = (e) => {
    const ingredientSearch = e.target.value;
    if (ingredientSearch.length < 3) {
      setIngredients([]);
    } else {
      getIngredients(e.target.value);
    }
  }

  const handleOnChangeMeasureId = (e) => {
    setMeasureId(e.target.value);
  }

  const handleResetIngredient = () => {
    setIngredientSearch('');
    document.querySelector('[name="ingredient_search"]').value = '';
    setIngredients([]);
    setIngredient(null);
    setIngredientSearchEnabled(true);
  }

  const handleResetTag = () => {
    setTagSearch('');
    document.querySelector('[name="tag_search"]').value = '';
    setTags([]);
  }

  const handleOnIngredientSelected = (label) => {
    document.querySelector('[name="ingredient_search"]').value = label;
    setIngredientSearch(label);
    setIngredients([]);
    setIngredientSearchEnabled(false);

    getIngredient(label);
  }

  const handleOnChangeFoodCategoryId = (e) => {
    setFoodCategoryId(e.target.value);
  }

  const handleOnChangeDescription = (e) => {
    setDescription(e.target.value);
  }

  const handleOnChangeStatus = (value) => {
    setStatus(value);
  }

  const onChangeImageFile = e => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImageData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleDeleteIngredient = (index) => {
    var currentIngredients = recipeIngredients.slice();
    currentIngredients.splice(index, 1);
    setRecipeIngredients(currentIngredients);
  }

  const handleDeleteStep = (index) => {
    var currentSteps = recipeSteps.slice();
    currentSteps.splice(index, 1);
    setRecipeSteps(currentSteps);
  }

  const handleDeleteTag = (index) => {
    var current = recipeTags.slice();
    current.splice(index, 1);
    setRecipeTags(current);
  }

  const onResetImageFile = e => {
    setImageFile(null);
    setImageData(null);
  };

  const handleOnStepChange = e => {
    setRecipeStep(e.target.value);
  }

  const handleEditIngredient = (i) => {
    const ingredient = recipeIngredients[i];
    setEditIngredientIndex(i);
    document.querySelector('[name="edit_quantity"]').value = ingredient?.quantity;
    document.querySelector('[name="edit_measure_id"]').value = ingredient?.measure_id;
    setShowEditIngredientForm(true);
  }

  const handleUpdateIngredient = (e) => {
    var current = recipeIngredients.slice();
    const measure_id = document.querySelector('[name="edit_measure_id"]').value;
    const measure = measureOptions.find((option) => option.value == measure_id);
    current[editIngredientIndex].quantity = document.querySelector('[name="edit_quantity"]').value;
    current[editIngredientIndex].measure_id = measure_id;
    current[editIngredientIndex].measure_label = measure.label;
    document.querySelector('[name="edit_quantity"]').value = '';
    document.querySelector('[name="edit_measure_id"]').value = '';
    setShowEditIngredientForm(false);
  }

  const handleEditStep = (i) => {
    const step = recipeSteps[i];
    setEditStepIndex(i);
    document.querySelector('[name="edit_recipe_step"]').value = step?.description;
    setShowEditStepForm(true);
  }

  const handleUpdateStep = (e) => {
    var current = recipeSteps.slice();
    current[editStepIndex].description = document.querySelector('[name="edit_recipe_step"]').value;
    document.querySelector('[name="edit_recipe_step"]').value = '';
    setShowEditStepForm(false);
  }

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <Toolbar to={-1}>
          <div className='text-md'>
            <span >Editar Receta</span>
          </div>
        </Toolbar>

        <div className="flex flex-col py-4 space-y-3">

          <Input name="name" label={'Nombre de la receta'} />

          <div className="hidden">
            <Input id="image_file" onChange={onChangeImageFile} onReset={onResetImageFile} name="image_file" type="file" placeholder="" label={'Imagen principal'} />
          </div>

          { 
            imageData ? 

            <div className=" flex justify-center py-4">
              <div className="relative flex justify-center items-center w-52 h-52">
                <div className="flex justify-center items-center rounded-full w-52 h-52 bg-buddy-bg-4 overflow-hidden">
                  <img onClick={ (e) => { document.querySelector('[name="image_file"]').click() } } className="h-full w-full object-cover object-center" src={ imageData } />
                </div>
                <button type='button' onClick={onResetImageFile} className='absolute -right-2 -top-2 flex items-center justify-center w-8'>
                    <div className='w-8 h-8 rounded-full bg-buddy-bg-5 flex flex-col justify-center items-center'>
                        <FontAwesomeIcon className='text-white' icon={faTimes} />
                    </div>
                  </button>
              </div>
              
            </div>
            :
            <div className="flex justify-center py-4">
              <div className="flex justify-center items-center rounded-full w-52 h-52 bg-buddy-bg-4">
                <button onClick={ (e) => { document.querySelector('[name="image_file"]').value = null; document.querySelector('[name="image_file"]').click() } } className="flex flex-col justify-center items-center text-buddy-text-1 space-y-2">
                  <CameraIcon className="size-6" />
                  <span className="text-sm">Añadir foto de portada</span>
                </button>
              </div>
            </div>
          }

          <Textarea value={description} onChange={handleOnChangeDescription} name="description" label="Descripción del plato" />

          <div className='w-full relative'>
            <div className="flex justify-between mb-4 items-center">
              <span className="text-white font-semibold text-md">Ingredientes</span>
              <span className="text-buddy-text-3 text-sm">Toca para editar</span>
            </div>
            
            
            {
              recipeIngredients.map((recipeIngredient, i) => {
                if (!recipeIngredient.deleted) {
                  return (
                    <div onClick={ (e) => { handleEditIngredient(i) }} key={i} className="bg-buddy-bg-5 rounded-lg p-2 my-3 text-sm text-buddy-text-1 justify-between flex items-center space-x-3">
                      <img className="w-5 h-5 rounded-full" src={recipeIngredient.image_url}></img>
                      <div className="grow">{recipeIngredient.label} { recipeIngredient.quantity } { recipeIngredient.measure_label}</div>
                      <button onClick={ (e) => { handleDeleteIngredient(i) }}>
                        <TrashIcon className="size-5 text-buddy-text-danger" />
                      </button>
                    </div>
                  )
                }
              })
            }

            <div className={ showEditIngredientForm || showAddIngredientForm ? 'hidden' : 'flex justify-end mt-2'}>
              <SecondaryButton onClick={ (e) => { setShowAddIngredientForm(true) }}>
                  <PlusIcon className="size-4 me-1" /> <span className="">Añadir</span>
              </  SecondaryButton>
            </div> 

            {
              showAddIngredientForm &&
              <div className="mt-9">
                <div className="flex space-x-4">
                  <div className="w-1/2 shrink-0 grow h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
                      {
                        ingredientSearch.length > 0 ? 
                        <div className="absolute right-0 bottom-0 top-0">
                            <button type='button' onClick={handleResetIngredient} className='flex items-center justify-center w-8 me-2 h-full'>
                                <div className='w-8 h-8 rounded-full bg-form-button-bg flex flex-col justify-center items-center'>
                                    <FontAwesomeIcon className='text-white' icon={faTimes} />
                                </div>
                            </button>
                        </div>
                        : ''
                      }
                      <input readOnly={!ingredientSearchEnabled} onFocus={handleOnFocusIngredientSearch} onChange={handleOnChangeIngredientSearch} autoComplete="new-password" aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl"
                          name="ingredient_search"
                          type="text"
                          placeholder="Buscar ingredientes"
                      />
                      <div className="absolute left-2 top-4">
                        <MagnifyingGlassIcon className="size-5 text-buddy-text-3" />
                      </div>
                  </div>
                  <div className="flex flex-col text-buddy-text-1 -mt-6">
                      <span className="text-xs text-center">Unidad</span>
                      <SelectRounded name="measure_id" onChange={handleOnChangeMeasureId} options={measureOptions} />
                  </div>
                  <div className="flex flex-col text-buddy-text-1 w-16 -mt-6">
                      <span className="text-xs mb-2 text-center">Cantidad</span>
                        <div className='w-full'>
                        <div className="w-full h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
                          <input  aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-center text-sm text-white placeholder:font-light rounded-xl"
                              name="quantity"
                              type="number"
                          />
                      </div>
                  </div>
                  </div>
                </div>

                <div id="ingredientsList" className="w-full z-10 absolute flex flex-col bg-opacity-90 bg-buddy-bg-5 rounded-lg overflow-hidden">
                  {
                    ingredients.map((ingredient, i) => {
                      
                      return (
                        <button key={i} onClick={ (e) => { handleOnIngredientSelected(ingredient) }} className="w-full text-start text-buddy-text-2 py-2 px-3 ">{ingredient}</button>
                      )
                    })
                  }
                </div> 
                <div className="flex justify-end mt-3 space-x-2">
                  <SecondaryButton onClick={ (e) => { setShowAddIngredientForm(false); handleResetIngredient() }}>
                      <XMarkIcon className="size-4 me-1" /> <span className="">Cancelar</span>
                  </SecondaryButton>
                  <SecondaryButton onClick={addIngredient}>
                      <CheckIcon className="text-primary size-4 me-1" /> <span className="text-primary">Guardar</span>
                  </SecondaryButton>
                </div> 
              </div>
            }
              <div className={ showEditIngredientForm ? '': 'hidden'}>
                <div className="flex justify-between space-x-4">
                  <div className="flex flex-col w-1/2 text-buddy-text-1">
                    <div className='w-full relative h-10 ring-2 ring-white rounded-lg'>
                      <div className="absolute top-1/2 -translate-y-1/2 left-2">
                      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.99992 14.5L5.80367 20.1263C5.948 21.1366 6.30756 21.5 7.38524 21.5H9.99996M4.99992 14.5H8.99996M4.99992 14.5C3.69683 13.8956 3 12.2637 3 10.8335C3 9.29807 4.02938 7.98314 5.49019 7.43701C5.80292 7.3201 5.99996 7.01809 5.99996 6.7085C5.99996 5.44284 7.11925 4.4165 8.49996 4.4165C9.16787 4.4165 9.80969 4.73873 10.4414 4.35059C11.3159 3.81321 12.368 3.5 13.5 3.5C16.5375 3.5 19 5.75723 19 8.54167C19 9.79823 21 10.1361 21 11.7502C21 13.0275 20.2877 14.1908 19 14.5M19 14.5H15M19 14.5L18.1961 20.1276C18.0438 21.1933 17.6349 21.5 16.6147 21.5H14M15 14.5H8.99996M15 14.5L14 21.5M8.99996 14.5L9.99996 21.5M9.99996 21.5H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                      <select name="edit_measure_id" className='w-full h-full bg-secondary-bg text-white rounded-lg ps-10 pe-6 appearance-none'>
                          <option value="">unidad</option>
                          {
                              measureOptions.map(option => {
                                  const {value, label} = option;
                                  return (
                                      <option type='button' key={value} value={value}>{label}</option>
                                  )
                              })
                          }
                      </select>
                      <div className='absolute top-2 rig right-2 pointer-events-none'>
                        <FontAwesomeIcon className='text-white pointer-events-none size-3' icon={faChevronDown} />
                      </div>
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-col text-buddy-text-1">
                    <div className="w-full h-10 bg-secondary-bg rounded-lg relative shadow-inner ring-white ring-2">
                      <div className="absolute top-1/2 -translate-y-1/2 left-2">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M15.0165 21.5C15.5718 21.5 16.022 21.0522 16.0221 20.5C16.0222 20.3437 16.0222 20.1886 16.0223 20.0509C16.0224 19.7433 16.1864 19.4871 16.4554 19.3355C16.653 19.2241 16.8459 19.1056 17.0338 18.9803C17.5251 18.6527 17.9823 18.2785 18.399 17.864C19.8143 16.4564 20.763 14.5847 20.9945 12.4981C21.0554 11.9492 20.5994 11.5 20.044 11.5H3.95595C3.40063 11.5 2.94457 11.9492 3.00547 12.4981C3.23696 14.5847 4.18566 16.4564 5.60101 17.864C6.02156 18.2823 6.48334 18.6595 6.97976 18.9893C7.12805 19.0878 7.27944 19.1821 7.43376 19.272C7.7645 19.4646 7.98374 19.8092 7.98244 20.1904L7.9814 20.4966C7.97951 21.0502 8.43024 21.5 8.9869 21.5H15.0165Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M6 5.5V7.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M18 5.5V7.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M12 3.5V7.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                      <input aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-white placeholder:text-white ps-10 rounded-lg"
                          name="edit_quantity"
                          type="number"
                          placeholder="cantidad"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end space-x-2 mt-3">
                  <SecondaryOutLineButton onClick={ (e) => { setShowEditIngredientForm(false) }}>
                      <XMarkIcon className="size-4 me-1" /> <span> Cancelar</span>
                  </SecondaryOutLineButton>
                  <SecondaryButton onClick={handleUpdateIngredient}>
                      <CheckIcon className="size-4 me-1 text-primary" /> <span className="text-primary"> Guardar</span>
                  </SecondaryButton>
                </div>
              </div>
          </div>

          
          
            <div>
              <div className='w-full relative'>
                <p className="text-white font-semibold text-md mb-2" htmlFor="email">Instrucciones</p> 
                   
                </div>
                {
                  recipeSteps.map((step, i) => {
                    return (
                      <div  key={i} onClick={(e) => { handleEditStep(i) }} className="bg-buddy-bg-5 rounded-lg p-2 my-3 text-sm text-buddy-text-1 justify-between flex items-center space-x-3">
                        <div className="grow">{step.description}</div>
                        <button onClick={ (e) => { handleDeleteStep(i) }}>
                          <TrashIcon className="size-5 text-buddy-text-danger" />
                        </button>
                      </div>
                    )
                  })
                }

                <div className={ showEditStepForm || showAddStepForm ? 'hidden' : 'flex justify-end mt-2'}>
                  <SecondaryButton onClick={ (e) => { setShowAddStepForm(true) }}>
                      <PlusIcon className="size-4 me-1" /> <span className="">Añadir</span>
                  </  SecondaryButton>
                </div> 

                {
                  showAddStepForm &&
                  <div>
                    <div className="flex space-x-4">
                      <div className="w-1/2 shrink-0 grow h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
                          <input onChange={handleOnStepChange} aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-white ps-9 pe-4 rounded-lg"
                              name="recipe_step"
                              type="text"
                              placeholder="Añadir paso"
                          />
                          <div className="absolute left-2 top-4">
                            <MagnifyingGlassIcon className="size-5 text-buddy-text-3" />
                          </div>
                      </div>
                    </div>
                    <div className="flex justify-end space-x-2 mt-3">
                      <SecondaryOutLineButton onClick={ (e) => { setShowAddStepForm(false) }}>
                          <XMarkIcon className="size-4 me-1" /> <span> Cancelar</span>
                      </SecondaryOutLineButton>
                      <SecondaryButton onClick={addStep}>
                          <CheckIcon className="size-4 me-1 text-primary" /> <span className="text-primary"> Guardar</span>
                      </SecondaryButton>
                    </div>
                    
                  </div>
                } 

                <div className={showEditStepForm ? '' : 'hidden'}>
                  <div className="flex space-x-4">
                    <div className="w-1/2 shrink-0 grow h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
                        <input aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-white ps-9 pe-4 rounded-lg"
                            name="edit_recipe_step"
                            type="text"
                        />
                        <div className="absolute left-2 top-4">
                          <MagnifyingGlassIcon className="size-5 text-buddy-text-3" />
                        </div>
                    </div>
                  </div>

                  <div className="flex justify-end space-x-2 mt-3">
                    <SecondaryOutLineButton onClick={ (e) => { setShowEditStepForm(false) }}>
                        <XMarkIcon className="size-4 me-1" /> <span> Cancelar</span>
                    </SecondaryOutLineButton>
                    <SecondaryButton onClick={handleUpdateStep}>
                        <CheckIcon className="size-4 me-1 text-primary" /> <span className="text-primary"> Guardar</span>
                    </SecondaryButton>
                  </div>
                </div>
              </div>


          <div>
            <Input name="rations" label={"Establecer raciones"} type="number" />
            <div className="text-xs text-primary mt-1 mb-2">Esto se utiliza para calcular las raciones en base a las cantidades y calcular el valor nutricional de la receta.</div>
          </div>


          <div className='w-full relative'>
            <p className="text-white font-semibold text-md mb-2" htmlFor="email">Añadir etiquetas a la receta</p> 
            <div className="flex space-x-4">
              <div className="w-1/2 shrink-0 grow h-12 bg-black bg-opacity-40 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
                  {
                    tagSearch.length > 0 ? 
                    <div className="absolute right-0 bottom-0 top-0">
                        <button type='button' onClick={handleResetTag} className='flex items-center justify-center w-8 me-2 h-full'>
                            <div className='w-8 h-8 rounded-full bg-form-button-bg flex flex-col justify-center items-center'>
                                <FontAwesomeIcon className='text-white' icon={faTimes} />
                            </div>
                        </button>
                    </div>
                    : ''
                  }

                  <input onFocus={handleOnFocusTagSearch} onChange={handleOnChangeTagSearch} autoComplete="new-password" aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full bg-transparent text-white ps-9 pe-4 placeholder:font-light rounded-xl"
                      name="tag_search"
                      type="text"
                      placeholder="Buscar etiquetas"
                  />
                  <div className="absolute left-2 top-4">
                    <MagnifyingGlassIcon className="size-5 text-buddy-text-3" />
                  </div>
              </div>
            </div>

            <div id="tagsList" className="w-full z-10 absolute flex flex-col bg-opacity-90 bg-buddy-bg-5 rounded-lg overflow-hidden">
              {
                tags.map((tag, i) => {
                  return (
                    <button key={i} onClick={ (e) => { addTag(tag) }} className="text-start text-buddy-text-2 py-2 px-3 ">{tag.name}</button>
                  )
                })
              }
            </div>
            <div className="my-3">
              {
                recipeTags.map((tag, i) => {
                  return (
                    <div key={i} className="bg-buddy-text-2 mb-2 mr-2 rounded-lg py-1 px-2 text-sm text-buddy-text-5 font-semibold justify-between inline-flex items-center space-x-3">
                      <div className="grow">{tag.name}</div>
                      <button className="bg-orange-500 rounded-lg p-1" onClick={ (e) => { handleDeleteTag(i) }}>
                        <XMarkIcon className="size-3 text-buddy-text-1" />
                      </button>
                    </div>
                  )
                })
              }
            </div>
          </div>

          <Input name="cook_time" label={"Tiempo de cocción (minutos)"} type="number" />

          <Input name="preparation_time" label={"Tiempo de preparación (minutos)"} type="number" />

          <Select name="food_category_id" label="Categoría" onChange={handleOnChangeFoodCategoryId} options={foodCategoryOptions} />

          <div className="">
            <SmallToggle selected={status} onChange={handleOnChangeStatus}  options={statusOptions} label="Estado de la Receta"  />
          </div>

          <div className="my-4"></div>

          <FooterToolbar>
            <PrimaryButton type="button" onClick={handleUpdate} >Guardar</PrimaryButton>
          </FooterToolbar>

        </div>
      </div>
    </div>
  );
};

export default RecipeCreate;
