import React, { useState, useEffect  } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom"; 
import { useNavigate } from 'react-router-dom';
import applelogo from "../img/Apple_logo_black.svg.png";
import { isAuthenticated } from '../auth';
import { getUserInfo } from '../auth';
import FooterMain from './FooterMain';

const formatFecha = (fecha) => {
  const [año, mes, dia] = fecha.split("-");
  return `${dia}/${mes}/${año.slice(2)}`;
};

const ListasDeLaCompra = () => {

  const [allLists, setAllLists] = useState([]); 
  const [filteredLists, setFilteredLists] = useState([]); // Store filtered lists
  const userinfo = getUserInfo();
  const id = userinfo.id;
  const navigate = useNavigate();
  const [nuevoId, setNuevoId] = useState(''); 

  const handleAgregarClick = async () => {
    
    fetch(`https://buddymarket.io/dummy/apibm/lists/create.php?id=${userinfo.id}`)
    .then(response => response.json())
    .then(data => {
      console.log(data);
      let listavalor = 'lista/'+data.data.last_id;
      window.location.href = listavalor;
      /*alert(listavalor);
      navigate(listavalor);*/
    })
    .catch(error => console.error('Error creating lists:', error));
    
  };

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/lists/readlists.php/?id=${id}`)
      .then(response => response.json())
      .then(data => {
        setAllLists(data.data);
        setFilteredLists(data.data); // Set initial filtered list to all lists
      })
      .catch(error => console.error('Error fetching lists:', error));
  }, []);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const filteredList = allLists.filter(list => list.nombre.toLowerCase().includes(searchTerm));
    setFilteredLists(filteredList);
  };

  return (  
    
    <div className="contenedor">
      <div className="titulin">
        <Link to='../dashboard'>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Mis listas de la compra</h2>      
      </div>

      <div className="rectangulo centrado busqueda">
        <form id="busqueda" action="buscar">
          <div className="input-container">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon2" />
            <input
              className="w100"
              type="text"
              name="searchlista"
              placeholder="Buscar lista"
              onChange={handleSearchChange}
            /><br />
          </div>
        </form>
      </div>
      
      <div className='w100 flex flexend mt20 mb10'>
        <div className="">
          <button
            className="bordeblanco mb10 f09"
            onClick={handleAgregarClick} // Attach click handler
          >
            <Link
              className="textoblanco" // Remove the to prop for a non-routing link
            >
              Nueva lista manual
            </Link>
          </button>
        </div>
      </div>
      <div className="rectangulo round mb20 mt0 relative p0">
        <div className='flex wrap'>  
          {(!!filteredLists) ? null : 'No hay resultados para la búsqueda'}
          {filteredLists?.map(list => (
            <a key={list.id} className="w100" href={`./lista/${list.id}`}>
              <div className='listacompralista1 flex spacebetween vcentrado w100 mb10 fondodos'>
                <span className="">{list.nombre} {formatFecha(list.fecha_alta)}</span>
                <span className="simbologran">&gt;</span>
              </div>
            </a>
          ))}
        </div>
      </div>
      <br /><br />
    </div>

  );
}

export default ListasDeLaCompra;

