import React from 'react';
import { useState, useEffect } from "react";
import { useNavigate, redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockFour } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faSquareCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom"; 
import { isBuddyMaker, getUserInfo } from "../auth";
import buddyia from '../img/buddyiagran.png'; 
import { faEdge } from '@fortawesome/free-brands-svg-icons';

const BuddyChat = () => {

  const buddymaker = isBuddyMaker();
  const [usuario, setUsuario] = useState([]);
  const userinfo = getUserInfo();
  const placecholderUsuario = "nouser.png";
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `https://buddymarket.io/dummy/apibm/users/read.php/?id=${userinfo.id}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUsuario(data.data);
        if(userinfo.id == null){navigate('../');}
      })
      .catch((error) => console.error("Error fetching user:", error));
  }, []);

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">BuddyMarket</h2>      
      </div>

      <hr className='fondodos'/>
      <div className='round fondouno mt-20 p10'>
        ...escribiendo
      </div>


      <div className='contenedorabajo'>
      <div className='flex wrap'>  
          <div className='listacompralista1 w100 mb10 fondodos'>
            <Link className="flex spacebetween vcentrado textoblanco vcentrado" to="../buddy-chat">
              <span className="">Hazme una pregunta</span>
              <FontAwesomeIcon icon={faPaperPlane} />
            </Link>
          </div>
        </div>   
      </div>
  </div>
  );
};

export default BuddyChat;