import React from 'react';
import { useState } from 'react';
import FooterMain from './FooterMain';
import { useNavigate, Link } from 'react-router-dom';

const TerminosyCondiciones = () => {

  const [token, setToken] = useState('');
  const navigate = useNavigate();

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Términos y condiciones</h2>      
      </div>
      <div className="rectangulo centrado busqueda">
        
      Los presentes Términos y Condiciones (en adelante, los “Términos y Condiciones”) regulan el acceso y uso de BUDDY MARKET propiedad de BUDDY MARKET (en adelante, la “Plataforma”), accesible desde el sitio web www.buddymarket.io (en adelante, el “Sitio Web”), con correo electrónico info@buddymarket.io , la cual es titularidad de  BUDDY MARKET, S.L., con CIF XXXXX, con domicilio social en la Calle Villanueva 2, 3ºc 28001, Madrid, (en adelante, “BUDDY MARKET”).  

OBJETO DE LA PLATAFORMA

El objeto de la plataforma BUDDY MARKET es proporcionar a los usuarios una herramienta eficiente y personalizada para la gestión de su compra en supermercados. Gracias a su avanzada inteligencia artificial, BUDDY MARKET permite a los usuarios optimizar su tiempo y presupuesto al generar listas de compras inteligentes basadas en sus preferencias alimenticias, recetas y dietas. También permite la comparación de precios entre diferentes productos y tiendas, favoreciendo el ahorro y la toma de decisiones de compra consciente.

BUDDY MARKET se adapta a las necesidades individuales de cada usuario, permitiéndoles personalizar su experiencia de compra al máximo. Ya sea que sigan una dieta específica, como vegana o sin gluten, o simplemente quieran organizar sus compras semanales de manera más eficiente, BUDDY MARKET puede ayudar.

La adquisición de la licencia de uso de BUDDY MARKET deberá realizarse siguiendo las instrucciones proporcionadas en estos Términos y Condiciones, y el usuario declara conocer y aceptar estos procedimientos como necesarios para acceder a los servicios ofrecidos en la plataforma.

ACEPTACIÓN DE LOS TÉRMINOS Y CONDICIONES

Mediante la aceptación de los Términos y Condiciones, el Usuario se compromete a actuar en todo momento de conformidad con las disposiciones establecidas en el clausulado del presente texto legal. En caso de no estar de acuerdo con todo o parte de estos Términos y Condiciones, el Usuario debe abstenerse de utilizar la Plataforma y los servicios en ella ofrecidos por BUDDY MARKET.

El servicio prestado en esta Plataforma se rige por: 

estos Términos y Condiciones; 
Condiciones Particulares que puedan publicarse en la Plataforma o que queden regulados y reflejados en el Sitio web;
las leyes vigentes y aplicables, regulaciones sectoriales y los criterios y prácticas generales.

Al aceptar los presentes Términos y Condiciones, el usuario manifiesta ser mayor de 18 años y/o tener capacidad legal plena para entender y aceptar el contenido de estos Términos y Condiciones, eximiendo y manteniendo indemne a BUDDY MARKET de cualesquiera reclamaciones, denuncias o daños causados por el incumplimiento de esta condición, la cual se entiende como conditio sine qua non para el uso y aprovechamiento de los servicios ofrecidos por BUDDY MARKET en la misma. 

En el caso de los usuarios menores de edad, se requiere que los padres o tutores legales firmen y acepten estos Términos y Condiciones en nombre del menor y se responsabilicen de su cumplimiento. Los padres o tutores legales asumen toda la responsabilidad y consecuencias legales derivadas del uso de los servicios por parte del menor.

REGISTRO

El Usuario deberá registrarse en la plataforma de BUDDY MARKET para acceder a sus servicios. Para completar el proceso de registro, el Usuario deberá proporcionar información precisa, actualizada y veraz, así como crear un nombre de usuario y una contraseña seguros.

El Usuario es el único responsable de mantener la confidencialidad de su nombre de usuario y contraseña, y de todas las actividades que ocurran bajo su cuenta. Se compromete a notificar de inmediato a BUDDY MARKET cualquier uso no autorizado de su cuenta o cualquier otra violación de seguridad.

El registro en la plataforma de BUDDY MARKET está sujeto a la aprobación de la empresa. BUDDY MARKET se reserva el derecho de rechazar o cancelar el registro de un Usuario en cualquier momento y por cualquier motivo, sin previo aviso.

El Usuario acepta que la información proporcionada durante el proceso de registro será verídica y precisa, y se compromete a mantenerla actualizada en todo momento. Asimismo, el usuario acepta que BUDDY MARKET pueda utilizar la información proporcionada durante el registro de acuerdo con su Política de Privacidad.

El Usuario declara ser el titular legítimo de la información proporcionada durante el registro y garantiza que tiene la capacidad legal para aceptar estos Términos y Condiciones.

El registro en la plataforma de BUDDY MARKET no garantiza el acceso inmediato a todos los servicios ofrecidos. Algunos servicios pueden estar sujetos a condiciones adicionales, como la aceptación de contratos específicos o el pago de tarifas adicionales.

BUDDY MARKET se reserva el derecho de modificar o cancelar el registro de un Usuario en caso de incumplimiento de estos Términos y Condiciones o de cualquier otra normativa aplicable.

El Usuario acepta que BUDDY MARKET no será responsable de ninguna pérdida o daño causado por el incumplimiento de las obligaciones relacionadas con el registro, incluyendo la pérdida de acceso a los servicios o la divulgación no autorizada de información personal.

El registro en la plataforma de BUDDY MARKET implica la aceptación de estos Términos y Condiciones, así como de cualquier otra política o condición adicional establecida por BUDDY MARKET.

ACCESO A LA PLATAFORMA

Acceso y uso de la Plataforma: 

El Usuario tendrá únicamente el derecho a acceder y usar la Plataforma para sus propósitos y en las condiciones establecidas en estos Términos y Condiciones. Cualquier otro acceso y/o uso de la Plataforma constituirá un supuesto de incumplimiento de conformidad con lo dispuesto en el presente Contrato.

Asimismo, el Usuario se compromete a mantener la confidencialidad de las cuentas, las credenciales y cualquier contraseña del Usuario necesaria para el acceso y utilización de la Plataforma, por lo que será responsable de cualquier uso dada a la Plataforma a través de las credenciales del Usuario o cualquier cuenta que el Usuario pueda establecer. 

En caso de pérdida, sustracción, sospecha de un uso no autorizado o cualquier otro hecho que pudiera afectar a la confidencialidad de sus cuentas, credenciales o contraseñas, el Usuario lo deberá notificar inmediatamente a BUDDY MARKET para que pueda adoptar las medidas necesarias. Hasta que BUDDY MARKET no sea informado de dichos hechos, quedará exonerado de toda responsabilidad por las operaciones que pudieran efectuarse utilizando las cuentas, credenciales o contraseñas del Usuario.

Requisitos técnicos para visualizar contenidos:

El contenido de la Plataforma solo se puede visualizar a través de los navegadores Chrome, Firefox, Safari. BUDDY MARKET no puede garantizar el acceso a través de Internet Explorer u otros navegadores nativos, o mediante navegadores no actualizados. Corresponde al Usuario garantizar la compatibilidad de su entorno informático y de sus terminales móviles, así como los de terceros, bajo su propia responsabilidad. 

En caso de modificación de la configuración inicial de uso por parte del Usuario que no cumpla con los requisitos previos indicados por BUDDY MARKET, BUDDY MARKET se mantendrá indemne de responsabilidad relativa al funcionamiento o disponibilidad de la Plataforma. 

OBLIGACIONES DE BUDDY MARKET

Proveer acceso a la Plataforma: BUDDY MARKET se compromete a brindar a los Usuarios registrados acceso a su Plataforma y a los servicios ofrecidos de acuerdo con los términos y condiciones establecidos. Esto incluye garantizar un funcionamiento adecuado de la Plataforma y la disponibilidad de los servicios, salvo en casos de interrupciones planificadas o imprevistas.

BUDDY MARKET tomará las medidas necesarias para proteger la seguridad de la Plataforma y de la información de los Usuarios. Esto implica implementar medidas de seguridad técnicas y organizativas adecuadas para prevenir accesos no autorizados, pérdidas, alteraciones o divulgación de datos personales.

BUDDY MARKET se compromete a cumplir con todas las leyes y regulaciones aplicables en relación con la prestación de sus servicios. Esto incluye el cumplimiento de las normas de protección de datos, la privacidad y cualquier otra legislación relevante en el ámbito de su actividad.

BUDDY MARKET brindará soporte y atención al cliente a los Usuarios para resolver consultas, problemas técnicos o cualquier otro tipo de inconveniente relacionado con la Plataforma. Esto puede incluir asistencia por correo electrónico, chat en línea u otros medios de comunicación establecidos por BUDDY MARKET.

BUDDY MARKET se compromete a habilitar la licencia de uso de la Plataforma una vez que se haya realizado el pago correspondiente. Una vez que el Usuario haya cumplido con sus obligaciones de pago, BUDDY MARKET procederá a activar y habilitar la licencia, lo que permitirá al Usuario acceder y utilizar plenamente los servicios y funcionalidades ofrecidos por la plataforma. Esta activación se realizará en un plazo razonable y de conformidad con los presentes términos y condiciones.

OBLIGACIONES DE LOS USUARIOS

Los Usuarios deben cumplir con los términos y condiciones establecidos por BUDDY MARKET en relación con el uso de los servicios. Esto incluye respetar las políticas de uso, restricciones y cualquier otra regla específica aplicable.

Los Usuarios deberán abonar el precio, siguiendo las indicaciones previstas en los presentes Términos y Condiciones. En caso de que se produzca un impago, BUDDY MARKET podrá suspender de forma inmediata y automática el acceso a todos los servicios formativos existentes en la Plataforma hasta que se regularice la situación de impagos.

Los Usuarios son exclusivamente responsables de garantizar la veracidad de los datos personales proporcionados en todo momento, de forma que tanto la identificación, como la prestación de los servicios por parte de BUDDY MARKET en la Plataforma, como la facturación por la prestación de los mismos se pueda llevar a cabo de forma correcta.

Igualmente, los Usuarios mantendrán en secreto las claves que BUDDY MARKET les proporcione en cada momento, siendo responsables por ello de cualquier uso ilegítimo o ilícito que se produzca con sus credenciales. Si los Usuarios tienen sospechas de que sus credenciales están siendo utilizadas por un tercero, deberán ponerlo en conocimiento de BUDDY MARKET de forma inmediata.

Los Usuarios se comprometen expresamente a la no reproducción, distribución, comunicación pública, transformación, comercialización y puesta a disposición del material didáctico en cualquier soporte o formato, puestos a su disposición en el Sitio Web.

DURACIÓN

La duración de la relación entre BUDDY MARKET y los Usuarios será determinada por el período de tiempo en que los Usuarios utilicen los servicios proporcionados por BUDDY MARKET. Los Usuarios podrán acceder y utilizar los servicios durante el tiempo que mantengan una cuenta activa y cumplan con los términos y condiciones establecidos.

BUDDY MARKET se reserva el derecho de suspender o cancelar la cuenta de un Usuario en caso de incumplimiento de los términos y condiciones, o si considera que la cuenta está siendo utilizada de manera fraudulenta, perjudicial o contraria a los intereses de BUDDY MARKET o de otros Usuarios.

Los Usuarios pueden optar por dar de baja su cuenta en cualquier momento, notificándolo a BUDDY MARKET de acuerdo con los procedimientos establecidos. En caso de terminación de la cuenta, los Usuarios ya no podrán acceder ni utilizar los servicios de BUDDY MARKET, y deberán cumplir con cualquier obligación pendiente, como el pago de tarifas o la devolución de materiales proporcionados por BUDDY MARKET.

Las disposiciones y derechos que, por su naturaleza, deban subsistir a la terminación de la relación entre BUDDY MARKET y los Usuarios, seguirán en vigor incluso después de la finalización de la cuenta o de la suspensión de los servicios.

EXCENCIÓN DE RESPONSABILIDAD

BUDDY MARKET se exonera de cualquier responsabilidad en caso de problemas relacionados con Open AI y cualquier otro proveedor de servicio, ya sea por la interrupción del servicio proporcionado por Open AI o por la prohibición legal en España u otros países donde se ofrezca el servicio. En tales circunstancias, BUDDY MARKET no asumirá ninguna obligación ni responsabilidad derivada de la interrupción temporal o permanente del servicio de ChatGpt, y no se le imputarán responsabilidades derivadas de dicha situación. 

BUDDY MARKET no será responsable de ningún, daño o perjuicio directo, indirecto, causado por los problemas con ChatGpt, incluyendo, pero no limitándose a, la interrupción del servicio, la pérdida de datos, la imposibilidad de acceso, los errores en las respuestas proporcionadas por ChatGpt u otras situaciones similares.

Asimismo, BUDDY MARKET no se hace responsable de las acciones, decisiones o políticas adoptadas por Open AI o las autoridades gubernamentales que puedan afectar la disponibilidad o el uso de ChatGpt. 

CONFIDENCIALIDAD

Los Usuarios se obligan a guardar secreto y confidencialidad sobre cuantos datos le sean facilitados por BUDDY MARKET, relativos a nombre de usuario y claves para acceder a la página web y chats incluidos en el Sitio Web y la Plataforma.

Los Usuarios se obligan a tratar confidencialmente toda la documentación y material didáctico, el cual se utilizará únicamente para su uso privado formativo, comprometiéndose a no reproducirlo, distribuirlo, comunicarlo públicamente, transformarlo, comercializarlo o ponerlo a disposición de terceros a través de cualquier formato, soporte o medio de difusión. 

Toda la información ya compartida y/o aún por compartir BUDDY MARKET y los Usuarios, facilitada con el objetivo de ejecutar la prestación de servicios formativos, será considerada confidencial, especialmente los datos de carácter personal de los Usuarios.  

PROTECCIÓN DE DATOS PERSONALES

Los Usuarios se comprometen a aceptar la Política de Privacidad la cual está alojada en el Sitio Web.

En consecuencia, los Usuarios están informados informado de que sus datos de carácter personal se almacenan con arreglo a lo dispuesto en el Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (en adelante, RGPD) y lo establecido en la normativa española vigente en esta materia.

De acuerdo con lo establecido en el RGPD, los datos personales de los Usuarios serán incorporados a nuestro sistema de tratamiento de datos personales, con la finalidad de gestionar la presente relación contractual y conservándose mientras se mantenga la misma, así como el tiempo necesario para el cumplimiento de responsabilidades por parte de BUDDY MARKET.

BUDDY MARKET informa de la posibilidad que tienen los Usuarios de ejercer los siguientes derechos sobre sus datos personales: derecho de acceso, rectificación, supresión u olvido, limitación, oposición, portabilidad y a retirar el consentimiento prestado (sin perjuicio de la legitimad de los tratamientos realizados mientras se prestó dicho consentimiento), lo que también aparece definido y explicado con más detalle en la Política de Privacidad de BUDDY MARKET.

Para ello podrá enviar un email a info@BUDDY MARKET.es , acompañado de una fotocopia del DNI, o dirigir un escrito ejerciendo sus derechos a la siguiente dirección postal: Calle Alameda, 22, Bajo 2, Madrid, España, 28014. Igualmente, los Usuarios podrán dirigir sus reclamaciones a la Agencia Española de Protección de Datos (https://www.aepd.es/es) si entienden que sus derechos no han sido atendidos correctamente.

PROPIEDAD INTELECTUAL E INDUSTRIAL

Mediante la aceptación de los presentes Términos y Condiciones, BUDDY MARKET manifiesta, y los Usuarios aceptan, que BUDDY MARKET es titular o cesionario de todos los derechos de propiedad intelectual, industrial y cualesquiera otros existentes sobre el Sitio Web, la Plataforma, nombres de dominio y cualesquiera otros que se entiendan incluidos en los mismos.

En este sentido, BUDDY MARKET y los Usuarios manifiestan y reconocen que en ningún caso la prestación de los servicios formativos aquí definidos se entenderá como una cesión, autorización, transferencia o licencia de los derechos de propiedad intelectual, industrial y de cualquier otro tipo de los que sea titular o cesionario  BUDDY MARKET, siendo sólo posible, por parte de los Usuarios, la utilización de cualesquiera contenidos, marcas, nombres de dominio y signos distintivos en el más estricto ámbito de la formación ofrecida por  BUDDY MARKET y única y exclusivamente mientras dure la relación contractual entre  BUDDY MARKET y los Usuarios.

Asimismo, los Usuarios tienen terminantemente prohibida la utilización, reproducción, copia, distribución, comunicación pública, transformación y/o cualquier otra explotación de, con carácter meramente enunciativo, pero no limitativo, el código fuente, código objeto, nombres de dominio y materiales didácticos, de parte o de totalidad del Sitio Web y/o la Plataforma, así como, de forma meramente enunciativa, pero no limitativa, cualquier acto de ingeniería inversa respecto del Sitio Web, la Plataforma y/o cualquiera de sus contenidos.

RESPONSABILIDAD

Los Usuarios mantendrán indemne a BUDDY MARKET en todo momento frente a cualquier reclamación, queja, sanción, multa o cualquier otra acción judicial y/o administrativa, pública y/o privada, que pueda suponer o haya supuesto un daño patrimonial o moral a BUDDY MARKET.

En relación con lo anterior, los Usuarios responderán frente a BUDDY MARKET por cualquier incumplimiento o actuación realizada por los mismos contraviniendo los presentes Términos y Condiciones, la legislación aplicable en cualquier materia y cualesquiera condiciones particulares.

MISCELÁNEA

En el caso de que cualquiera de las previsiones de estos Términos y Condiciones fuera considerada en todo o en parte contraria a la Ley o inejecutable, quedará eliminada de los mismos y, si ello fuera posible, se sustituirá por otra estipulación con similar efecto a la anulada, continuando en todo caso la validez del contrato en sus estipulaciones restantes, que no se verán afectadas por la nulidad parcial.

Los presentes Términos y Condiciones están redactados en español, sin perjuicio de que se puedan traducir bajo determinadas condiciones a otros idiomas, como el inglés. Sin perjuicio de lo anterior, en caso de duda o conflicto entre la redacción de los Términos y Condiciones en la versión española y cualquier otro idioma, prevalecerá siempre la versión española.

BUDDY MARKET se reserva el derecho a modificar la presentación, configuración y contenido de los presentes Términos y Condiciones, así como de la Plataforma, y/o las condiciones requeridas para su acceso y/o utilización, previa notificación por escrito a los Usuarios. El acceso y utilización de los contenidos y servicios tras la entrada en vigor de sus modificaciones o los cambios en las condiciones suponen la aceptación de las mismas.

Los presentes Términos y Condiciones se regirán por la legislación española. En caso de litigio sobre la interpretación, ejecución o validez de estos Términos y Condiciones, serán competentes los Juzgados y Tribunales del territorio de residencia del Alumno.

En virtud de lo dispuesto en el Reglamento (UE) nº 524/2013 del Parlamento Europeo y del Consejo, de 21 de mayo de 2013, relativo a la resolución de litigios en línea en materia de consumo, BUDDY MARKET también le informa que, en caso de controversia, los Usuarios residente de la Unión Europea podrán acudir a la “Plataforma Online de Resolución de Conflictos” que ha desarrollado la Comisión Europea, con el fin de intentar resolver extrajudicialmente cualquier controversia que se derive de la prestación de servicios por parte de  BUDDY MARKET.
Para a acceder a la “Plataforma Online de Resolución de Conflictos” puede hacerlo a través del siguiente enlace: http://ec.europa.eu/consumers/odr/

      </div>
      
      <br /><br />
      <FooterMain />
    </div>
    
  );
};

export default TerminosyCondiciones;