import React, {useState, useEffect} from 'react'
import FoodCategory from '../../api/services/FoodCategory';
import { Link } from 'react-router-dom';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FoodCategoryTile = ({ foodCategory, to = '' }) => {

    const [img, setImg] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        //setLoading(true);
        //getImage();
    }, []);
    
    const getImage = async () => {
        try {
            const response = await FoodCategory.image(foodCategory.id);
            const blob = await response.blob();
            setImg(URL.createObjectURL(blob));
        } catch (error) {
            console.error('Error fetching recipes:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Link to={to} className="relative flex-none w-28 mr-3 mb-2 rounded-lg">
                <div className="relative">
                    <div className="w-full overflow-hidden rounded-lg bg-black">
                        {
                            loading ?
                            <div className="bg-black h-28 w-28 object-cover flex items-center justify-center"><FontAwesomeIcon icon={faCircleNotch} className='animate-spin text-white' /></div>
                            :
                            <img src={foodCategory.image_url} alt="" className="bg-black brightness-50 h-28 w-28 object-cover object-center" />
                        }
                    </div>
                </div>
                <div className='absolute bottom-3 w-full text-center text-buddy-text-1 text-xs'>{foodCategory.name}</div>
            </Link>
            
        </>
    )
}

export default FoodCategoryTile 