import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Recipe from "../api/services/Recipe.js";
import { useSearchParams } from "react-router-dom";
import FooterToolbar from "./ui/FooterToolbar.js";
import PrimaryButton from "./ui/PrimaryButton.js";
import Toolbar from "./ui/Toolbar.js";
import { Input } from "postcss";

const RecipeSteps = () => {

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [recipe, setRecipe] = useState(null);

  const id = searchParams.get('id') ?? '';
  
  useEffect(() => {    
    getRecipe(id);
  }, []);

  const getRecipe = async (id) => {
    try {
      const response = await Recipe.get(id);
      setRecipe(response.data);
    } catch (error) {
      console.error('Error fetching author:', error);
    }
  };

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <Toolbar to={-1}>
          <div className='text-md'>
            <span >Editar receta: pasos</span>
          </div>
        </Toolbar>

        <div className="flex flex-col py-4 space-y-3">

          <Input name="name" label={'Nombre de la receta'} />

        </div>
      </div>
    </div>
  );
};

export default RecipeSteps;
