import React, { useState } from 'react'

const SecondaryButton = ({ size = 'md', children, ...attributes }) => {

    return (
        <>
            <button className="flex items-center bg-primary-button-bg bg-opacity-40 py-2 px-3 rounded-lg text-sm text-buddy-text-1"
                {...attributes}>
                    {children}
            </button>
        </>
    )
}
export default SecondaryButton 