import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import SelectList from './forms/SelectList';
import HydrationLevel from '../api/services/HydrationLevel';

const ProfileForm28 = () => {
  const navigate = useNavigate();

  const [hydrationLevelId, setHydrationLevelId] = useState('');
  const [hydrationLevelOptions, setHydrationLevelOptions] = useState([]);
  
  const handleClick = (e) => {
    var data = JSON.parse(localStorage.getItem('register_data'));
    data.hydration_level_id = hydrationLevelId;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/signUpConfirm');
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    loadHydrationLevelOptions();
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setHydrationLevelId(data.hydration_level_id ?? '');
    }
  }, []);

  const handleOnChangeHydrationLevelId = (value) => {
    setHydrationLevelId(value);
  }

  async function loadHydrationLevelOptions() {
    try {
      const response = await HydrationLevel.list();
      var options = [];
      response.data.map(level => {
        options.push({
          value: level.id,
          label: level.name
        });
      });
      setHydrationLevelOptions(options);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={-1}>
              <div className='text-sm'>
                <span >Paso 28</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>¿Cómo evaluarías tu nivel de hidratación diaria?</h2>
            <SelectList onChange={handleOnChangeHydrationLevelId} selected={hydrationLevelId} options={hydrationLevelOptions} />
            
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm28;