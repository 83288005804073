import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const AuthTokenInput = ({ label = null, onChange = null }) => {

    const handleInputChange = (e) => {
        const value = e.target.value;
        const number = parseInt(e.target.dataset.number);
        var nextInput = null;
        var previousInput = null;
        if (number < 4) {
            nextInput = "input_" + (number + 1);
        }
        if (number > 1) {
            previousInput = "input_" + (number - 1);
        }
        if (value.length == 1 && nextInput) {
            document.getElementById(nextInput).focus();
        }

        const input1 = document.getElementById('input_1').value;
        const input2 = document.getElementById('input_2').value;
        const input3 = document.getElementById('input_3').value;
        const input4 = document.getElementById('input_4').value;
        const code = "" + input1 + input2 + input3 + input4;
        
        if (onChange && code.length == 4) {
            onChange(code)
        } 
    }

    useEffect(() => {
        const el = document.getElementById('input_1');
        if (el) {
          el.focus();
        }
    }, []);

    const handleKeyPress = (e) => {
        // Backspace
        if (e.key == "Backspace") {
            const number = parseInt(e.target.dataset.number);
            const value = e.target.value;
            var previousInput = null;
            if (number > 1) {
                previousInput = "input_" + (number - 1);
            }
            if (value.length == 0 && previousInput) {
                document.getElementById(previousInput).focus();
            }
        }
      }

    return (
        <>
            <div className='w-full'>
                {
                    label != null ? <p className="text-white font-semibold text-md mb-2">{label}</p> : ''
                }
                
                <div className='flex space-x-4'>
                    <div className="w-full aspect-square bg-buddy-bg-1 rounded-3xl relative shadow-inner ring-inset ring-buddy-text-3 focus:ring-primary ring-1">
                        <input id='input_1' type="tel" inputMode='numeric' pattern='[0-9]{1}' maxLength="1" min="0" max="1" onKeyDown={handleKeyPress}  onChange={handleInputChange} data-number="1" autoComplete="off"  className="appearance-none text-3xl font-semibold text-center w-full h-full  bg-transparent text-white rounded-3xl" />
                    </div>
                    <div className="w-full aspect-square bg-buddy-bg-1 rounded-3xl relative shadow-inner ring-inset ring-buddy-text-3 focus:ring-primary ring-1">
                        <input id='input_2' type="tel" inputMode='numeric' pattern='[0-9]{1}' maxLength="1" min="0" max="1" onKeyDown={handleKeyPress}  onChange={handleInputChange} data-number="2" autoComplete="off" className="appearance-none text-3xl font-semibold text-center w-full h-full bg-transparent text-white rounded-3xl" />
                    </div>
                    <div className="w-full aspect-square bg-buddy-bg-1  rounded-3xl relative shadow-inner ring-inset ring-buddy-text-3 focus:ring-primary ring-1">
                        <input id='input_3' type="tel" inputMode='numeric' pattern='[0-9]{1}' maxLength="1" min="0" max="1" onKeyDown={handleKeyPress}  onChange={handleInputChange} data-number="3" autoComplete="off"  className="appearance-none text-3xl font-semibold text-center w-full h-full bg-transparent text-white rounded-3xl" />
                    </div>
                    <div className="w-full aspect-square bg-buddy-bg-1  rounded-3xl relative shadow-inner ring-inset ring-buddy-text-3 focus:ring-primary ring-1">
                        <input id='input_4' type="tel" inputMode='numeric' pattern='[0-9]{1}' maxLength="1" min="0" max="1" onKeyDown={handleKeyPress}  onChange={handleInputChange} data-number="4" autoComplete="off" className="appearance-none text-3xl font-semibold text-center w-full h-full bg-transparent text-white rounded-3xl" />
                    </div>
                </div>
            </div>            
        </>
    )
}

export default AuthTokenInput 