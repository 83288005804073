import React from 'react'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Select = ({ name, value, label = '', placeholder, placeholderValue = "", onChange = null, disabled = false, options = [] }) => {
    return (
        <>
            <div>
                {
                    label != null ? <p className="text-white font-semibold text-md mb-2" htmlFor="email">{label}</p> : ''
                }
                    
                <div className='w-full relative'>
                    <select disabled={disabled} name={name} onChange={onChange} value={value} className='w-full h-12 text-white bg-form-control-bg rounded-lg ps-4 pe-12 appearance-none'>
                        {
                            placeholder ? <option value={placeholderValue}>{placeholder}</option> : ''
                        }
                        {
                            options.map(option => {
                                const {value, label} = option;
                                return (
                                    <option type='button' key={value} value={value}>{label}</option>
                                )
                            })
                        }
                    </select>
                    <div className='absolute top-3 right-4 pointer-events-none'>
                        <FontAwesomeIcon className='text-white pointer-events-none' icon={faChevronDown} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Select 