import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Select from './forms/Select';
import MonthlySpendingRange from '../api/services/MonthlySpendingRange';

const ProfileForm4 = () => {
  const navigate = useNavigate();

  const [options, setOptions] = useState([]);
  const [monthlySpentRangeId, setMonthlySpentRangeId] = useState('');
    
  const handleClick = (e) => {
    var data = JSON.parse(localStorage.getItem('register_data'));
    data.monthly_spending_range_id = parseInt(document.querySelector('[name="monthly_spending_range_id"]').value);
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm5');
  }

  const handleOnChange = (e) => {}

  useEffect(() => {
    window.scrollTo(0, 0)
    loadOptions();
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setMonthlySpentRangeId(data.monthly_spending_range_id);
    }
  }, []);

  async function loadOptions() {
    try {
      const response = await MonthlySpendingRange.list();
      var options = [];
      response.data.map(range => {
        options.push({
          value: range.id,
          label: range.name
        });
      });
      setOptions(options);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={-1}>
              <div className='text-sm'>
                <span >Paso 4</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>¿Cuál es tu rango de gasto promedio en compras mensuales? <span className='text-primary'>(opcional)</span></h2>
            <Select placeholder={'Seleccionar rango'} placeholderValue='' onChange={handleOnChange} value={monthlySpentRangeId} key="monthly_spending_range_id" name="monthly_spending_range_id" options={options} />
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm4;