import React, { useState } from 'react'

const DangerButton = ({ children, ...attributes }) => {
    return (
        <>
            <button className='text-lg text-buddy-text-danger w-full p-3 font-semibold rounded-xl'
                {...attributes}>
                    {children}
            </button>
        </>
    )
}
export default DangerButton 