import React from 'react'

const Card = ({ children, className = '' }) => {
    return (
        <>
            <div className={`w-full bg-opacity-80 rounded-2xl p-4 ${className}`}>
                {children} 
            </div>
        </>
    )
}

export default Card 