import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import MeasureInput from './forms/MeasureInput';

const ProfileForm17 = () => {
  const navigate = useNavigate();

  const [age, setAge] = useState('');
 
  const handleClick = (e) => {
    const data = JSON.parse(localStorage.getItem('register_data'));
    data.age = age;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm18');
  }

  const handleOnChangeAge = (value) => {
    setAge(value);
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setAge(data.age ?? '');
    }
  }, []);

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={-1}>
              <div className='text-sm'>
                <span >Paso 17</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>Edad <span className='text-primary'>(opcional)</span></h2>
            <div className='my-2'></div>
            <div className='grid grid-cols-2 items-center gap-6 w-full px-12'>
              <MeasureInput id="age" name="age" type="number" value={age} onChange={handleOnChangeAge}></MeasureInput>
              <span className='text-2xl text-buddy-text-2 font-bold'>Años</span>
            </div>
            
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm17;