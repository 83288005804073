import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const Compartir = () => {
  const [urlAnterior, setUrlAnterior] = useState(window.location.href);
  const { url } = useParams();  
  const handleCompartir = (redSocial, url) => {

    switch (redSocial) {
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?url=${url}`;
        break;
      case "whatsapp":
        url = `https://api.whatsapp.com/send?text=${url}`;
        break;
      case "linkedin":
        url = `https://www.linkedin.com/shareArticle?url=${url}`;
        break;
      default:
        break;
    }
    window.open(url, "_blank");
  };

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">BuddyMarket</h2>      
      </div>
      <div className="redes-sociales rectangulo round relative fondooscuro">
      <h2 className="mb40 centrado">Compartir en</h2>
        <ul className="flex flexgrow1 w100 wrap equiespaciado gap20 rgap40 mb40">
          <li className="w50 centrado pointer" onClick={() => handleCompartir("facebook",url)}>
            <FontAwesomeIcon icon={faFacebook} className="f20" /><br />Facebook
          </li>
          <li className="w40 centrado pointer" onClick={() => handleCompartir("twitter",url)}>
            <FontAwesomeIcon icon={faTwitter} className="f20" /><br />X
          </li>
          <li className="w50 centrado pointer" onClick={() => handleCompartir("whatsapp",url)}>
            <FontAwesomeIcon icon={faWhatsapp} className="f20" /><br />Whatsapp
          </li>
          <li className="w40 centrado pointer" onClick={() => handleCompartir("linkedin",url)}>
            <FontAwesomeIcon icon={faLinkedin} className="f20" /><br />Linkedin
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Compartir;