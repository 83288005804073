import React from 'react';
import { Link } from 'react-router-dom';
import './footermain.css';
import imginicio from "../img/circle-notch.png";
import imgmicro from "../img/microphone.png";
import imgcamara from "../img/camera.png";
import imgpen from "../img/pen-square.png";
import NuevaReceta from './RecetaNueva';

const FooterMain = () => {
  return (
    <>
    <div></div>
    {/*<footer className='footermain'>
      <nav>
        <ul className='flex equiespaciado'>
          <li>
            <Link to="/dashboard"><img src={imginicio} /></Link>
          </li>
          <li>
            <Link to="/buddy"><img src={imgmicro} /></Link>
          </li>
          <li>
            <Link to="/about"><img src={imgcamara} /></Link>
          </li>
          <li>
            <Link to="/recetas"><img src={imgpen} /></Link>
          </li>
        </ul>
      </nav>
    </footer>*/}
    </>
  );
};

export default FooterMain;