import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const PrimaryTextLink = ({ children, ...attributes }) => {
    return (
        <>
            <div className='text-white w-full p-3 text-lg text-center font-semibold '>
                <Link className="w-full h-full" to={attributes.to}>{children}</Link>
            </div>
        </>
    )
}
export default PrimaryTextLink 