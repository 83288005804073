import React from 'react'

const SelectList = ({ selected, onChange = null, options = [] }) => {

    const handleOptionSelected = (e) => {
        const value = parseInt(e.target.dataset.value);
        if (onChange != null) {
            onChange(value);
        }
    }

    return (
        <>
            <div className='w-full'>
                <div className="w-full flex flex-col gap-3">
                    {
                        options.map(option => {
                            const {value, label} = option;
                            const optionsClassName = selected === value ? 'w-full text-start py-5 px-4 text-md rounded-xl font-semibold bg-primary' : 'w-full text-start py-5 px-4 text-md rounded-xl font-semibold bg-white';
                            return (
                                <button type='button' onClick={handleOptionSelected} key={value} className={optionsClassName} data-value={value}>{label}</button>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default SelectList 