import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import Gender from '../api/services/Gender';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { LockClosedIcon, EnvelopeIcon  } from '@heroicons/react/24/outline'
import DangerButton from './ui/DangerButton';
import PasswordWithValidationInput from './forms/PasswordWithValidationInput'
import Input from './forms/Input';
import AuthTokenInput from './forms/AuthTokenInput';
import PrimaryButton from './ui/PrimaryButton';
import User from '../api/services/User';

const UserUpdatePassoword = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const [authTokenRequested, setAuthTokenRequested] = useState(false);
  const [authTokenCode, setAuthTokenCode] = useState(null);

  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');

  const handleClick = async (e) => {
    if (authTokenRequested) {
      // Go update
      try {
        await User.updatePassword(user.id, {
          code: authTokenCode,
          current_password: currentPassword,
          password: password,
        });
        navigate('/userUpdatePasswordSuccess');
      } catch (error) {
        console.log(error);
        alert("Ocurrió un error al intentar actualizar la contraseña.");
      }
    } else {
      const password = document.querySelector('[name="password"]').value;
      const passwordRepeat = document.querySelector('[name="password_repeat"]').value;
      const currentPassword = document.querySelector('[name="current_password"]').value;
      if (validatePassowrd(password, passwordRepeat)) {
        setPassword(password);
        setCurrentPassword(currentPassword);
        try {
          await User.createAuthToken(user.id);
          setAuthTokenRequested(true);
        } catch (error) {
          console.log(error);
          alert("Ocurrió un error al intentar actualizar la contraseña.");
        }
      } else {
        alert("Datos no válidos. Comprueba que la contraseña nueva cumple con los requisitos de seguridad y que coincide con la confirmación.");
      }
    }
  }

  const validatePassowrd = (password, passwordRepeat) => {
    const hasNumber = /\d/;
    const hasSpecial = /[!@#$%^&*()_+\-=\[\]{};:\\|,.<>\/?~]/;
    if (password === passwordRepeat && password.length >= 8 && hasNumber.test(password) && hasSpecial.test(password)) {
      return true;
    }
    return false;
}

  useEffect(() => {
    const el = document.querySelector('[name="current_password"]');
    if (el) {
      el.focus();
    }
  }, []);
  
  const handleCodeChange = (value) => {
    setAuthTokenCode(value);
  }

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <div className="flex flex-col bg-red items-center gap-3">
          <Toolbar to={-1}>
            <div className='text-md'>Cambiar contraseña</div>
          </Toolbar>
          <div className='mt-6'></div>
          {
            authTokenRequested ?
            <div className='w-full'>
              <h2 className='text-buddy-text-1 text-center text-lg font-bold my-4'>Consulta tu correo electrónico</h2>
              <p className='text-buddy-text-2 text-center'>Hemos enviado un código de confirmación a tu correo electrónico</p>
              <div className='my-8'></div>
              <AuthTokenInput onChange={handleCodeChange} />
            </div>
            :
            <div className='w-full'>
              <Input name='current_password' type='password' label="Introduce tu contraseña actual" />
              <div className='my-4'></div>
              <PasswordWithValidationInput name='password' label="Introduce tu nueva contraseña" />
              <Input name='password_repeat' type='password' label="Confirma tu nueva contraseña" />
            </div>
          }
        </div>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleClick}>{ authTokenRequested ? "Confirmar" : "Guardar cambios" }</PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default UserUpdatePassoword;