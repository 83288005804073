import React from 'react';
import {useNavigate } from 'react-router-dom';
import thanksImage from '../img/gracias_rec.png';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';


const UserUpdateEmailSuccess = (data) => {
  const navigate = useNavigate();
  console.log(data);

  const handleClick = async (e) => {
    navigate('/', { replace: true});
  }

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6 pt-32'>
        <div className='flex flex-col h-full justify-center'>
          <div className='w-full text-center'>
            <img className='inline w-1/2 mb-8 ' src={thanksImage} alt="Correco electrónico actualizado correctamente" />
          </div>
          <h2 className='text-center text-buddy-text-1 text-2xl font-semibold'>¡Correo electrónico cambiada!</h2>
          <p className='text-buddy-text-3 text-center font-semibold my-4'>Tu correo electrónico ha sido cambiado exitosamente</p>
        </div>
      </div>
      <FooterToolbar>
        <PrimaryButton onClick={handleClick} type="button">Volver</PrimaryButton>
      </FooterToolbar>
    </div>
  );
};

export default UserUpdateEmailSuccess;