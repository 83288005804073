import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/fontawesome-free-regular'

const CheckBoxSelect = ({ selection, onChange = null, options = [] }) => {

    const handleOptionSelected = (e) => {
        const value = parseInt(e.target.dataset.value);
        var currentSelection = selection.slice();
        if (!currentSelection.includes(value)) {
            currentSelection.push(value);
        } else {
            currentSelection.splice(currentSelection.indexOf(value), 1);
        }
        if (onChange != null) {
            onChange(currentSelection);
        }
    }

    return (
        <>
            <div className='w-full'>
                <div className="">
                    {
                        options.map(option => {
                            const {value, label} = option;
                            return (
                                <button key={value} data-value={value} onClick={handleOptionSelected} className='mr-2 mb-2 inline-block bg-transparent ring-1 ring-white text-md text-white py-2 px-2 rounded justify-between items-center space-x-2'>
                                    <span className='pointer-events-none'>{label}</span>
                                    {
                                        selection.includes(value) ?  <FontAwesomeIcon className='text-primary pointer-events-none' icon={faCircleCheck} /> : <FontAwesomeIcon className='text-white pointer-events-none' icon={faCircle} />
                                    } 
                                </button>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default CheckBoxSelect 