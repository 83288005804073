import React from 'react';
import { useState, useEffect } from "react";
import { useNavigate, redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockFour } from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { faUtensils } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faSquareCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom"; 
import { isBuddyMaker, getUserInfo } from "../auth";
import buddyia from '../img/buddyiagran.png'; 
import { faEdge } from '@fortawesome/free-brands-svg-icons';

const BuddyResumen = () => {

  const buddymaker = isBuddyMaker();
  const [usuario, setUsuario] = useState([]);
  const userinfo = getUserInfo();
  const placecholderUsuario = "nouser.png";
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `https://buddymarket.io/dummy/apibm/users/read.php/?id=${userinfo.id}`
    )
      .then((response) => response.json())
      .then((data) => {
        setUsuario(data.data);
        if(userinfo.id == null){navigate('../');}
      })
      .catch((error) => console.error("Error fetching user:", error));
  }, []);

  return (
    <div className="contenedor">
      <div className="titulin flex row">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>

        {usuario?.map(({ id, username, foto }) => (
          <div key={id} className='w80' >
            <span className='simbologran'></span>
            <a key={id} className="centrado row flex gap10 rgap0 buddys vcentrado pt10" href={`perfil/${id}`}>
              <span className="f12 textoblanco">¡Hola {username}!</span>
                <img
                className="usuario w80"
                src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/users/${
                  foto || placecholderUsuario
                }`}
                alt={username}
              />
              
            </a>
          </div>
        ))}
           
      </div>
      
      <div className="rectangulo round mb20 mt20 relative p0">
        <h2 className='mb10'>Reciente</h2>
        <div className='flex wrap spacebetween row'>  
          <div className='listacompralista1 flex flexstart centrado column w49 mb10 fondouno p20'>
            <Link className=" textoblanco" to="#">
              <h3>Menú semanal</h3><br />
              <span className='w100 centrado'>20/06/2024</span><br /><br />
              <div className="w100 flex relative flexstart gap20 mb20 p0-10">
                <FontAwesomeIcon icon={faUtensils} className='f20 textonaranja' />
                <span>Comidas</span>
              </div>
              <div className="w100 flex relative flexstart gap20 mb20 p0-10">
                <FontAwesomeIcon icon={faUtensils} className='f20 textonaranja' />
                <span>Meriendas</span>
              </div>
              <div className="w100 flex relative flexstart gap20 mb20 p0-10">
                <FontAwesomeIcon icon={faUtensils} className='f20 textonaranja' />
                <span>Cenas</span>
              </div>
            </Link>
          </div>
          <div className='listacompralista1 flex flexstart centrado column w49 mb10 fondouno p20'>
            <Link className=" textoblanco" to="#">
              <h3>Lista de la compra</h3><br />
              <ul className='lista izquierda'>
                <li>Pechuga de pollo</li>
                <li>Tomates cherry</li>
                <li>Queso fresco</li>
                <li>Atún en lata</li>
                <li>Leche desnatada</li>
                <li>Kefir</li>
              </ul>
            </Link>
          </div>
        </div>
      </div>

      <div className="rectangulo round mb20 mt0 relative p0 fondodos">
        <div className='contenedor flex spacebetween row nowrap'>
          <button className="botoncirculo button fondonaranja textoblanco" type="button">
            <FontAwesomeIcon icon={faClockFour} className="f16" />
          </button>
          <div>
            <h2>Historial de chat</h2>
            <p>Chat anterior con historial de IA</p>
          </div>
        </div>
        <div>
        <form id="busqueda" action="buscar" className='w90 m20 mb10'>
          <div className="input-container centrado">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon2" />
            <input
              className="w100"
              type="text"
              name="user"
              autoComplete="off"
              placeholder="Buscar historial de chaat"
              value=''
              onChange=''
            />
            <br />
          </div>
        </form>
        <br />
        </div>
      </div>
      <div className="rectangulo round mb20 mt0 relative p0 fondodos">
        <div className='contenedor flex row nowrap vtop'>
            <FontAwesomeIcon icon={faClockFour} className="f16 textonaranja w10" />
            <div>
              <h2>Calorías de 199gr de pasta cocida</h2>
              <p>La densidad calórica del arroz y la pasta son bastante similares desde el punto de vista práctico: unas 350 kcal por cada 100 gramos el arroz, frente a 370 kcal, la pasta. Sin embargo, en cuanto al valor nutricional sí que hay mayor diferencia entre ellos.</p>
            </div>
        </div>
        <hr className='derecho w80'/>
        <div className='contenedor flex row nowrap vtop'>
            <FontAwesomeIcon icon={faClockFour} className="f16 textonaranja w10" />
            <div>
              <h2>Calorías de 199gr de pasta cocida</h2>
              <p>La densidad calórica del arroz y la pasta son bastante similares desde el punto de vista práctico: unas 350 kcal por cada 100 gramos el arroz, frente a 370 kcal, la pasta. Sin embargo, en cuanto al valor nutricional sí que hay mayor diferencia entre ellos.</p>
            </div>
        </div>
      </div>
  </div>
  );
};

export default BuddyResumen;