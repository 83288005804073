import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import Gender from '../api/services/Gender';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { LockClosedIcon, EnvelopeIcon  } from '@heroicons/react/24/outline'
import DangerButton from './ui/DangerButton';

const Security = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const [userAvatarUrl, setUserAvatarUrl] = useState('');
  

  const [options, setOptions] = useState([]);
  const [genderId, setGenderId] = useState('');

  const handleClick = (e) => {
    var data = JSON.parse(localStorage.getItem('register_data'));
    data.gender_id = genderId;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm3');
  }

  useEffect(() => {
    setUserAvatarUrl("https://ui-avatars.com/api/?rounded=true&name=" + user.name);
  }, []);
  const handleOnChange = (value) => {
    setGenderId(value);
  }

  const handleLogoutButton = (e) => {
    if (window.confirm("Cerrar sesión") == true) {
      localStorage.setItem('user', null);
      localStorage.setItem('token', null);
      navigate('/login');
    }
  }

  const handleDeleteAccountButton = (e) =>  {
    alert ("Delete account");
  }

  async function loadGenders() {
    try {
      const response = await Gender.list();
      var gendersOptions = [];
      response.data.map(gender => {
        gendersOptions.push({
          value: gender.id,
          label: gender.name
        });
      });
      setOptions(gendersOptions);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <div className="flex flex-col bg-red items-center gap-3">
          <Toolbar to={-1}>
            <div className='text-md'>Seguridad</div>
          </Toolbar>

          <Link className='w-full' to={'/userUpdatePassword'}>
            <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
              <LockClosedIcon className='size-6 text-buddy-text-1 grow-0' />
              <div className='grow px-2 text-start'>
                <span className='text-buddy-text-1 font-semibold grow-0'>Cambiar contraseña</span>
              </div>
              <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
            </div>
          </Link>

          <Link to={'/userUpdateEmail'} className='w-full'>
            <div className='w-full flex justify-between bg-buddy-bg-2 p-3 rounded-2xl'>
              <EnvelopeIcon className='size-6 text-white grow-0' />
              <div className='grow px-2 text-start'>
                <span className='text-buddy-text-1 font-semibold grow-0'>Cambiar correo electrónico</span>
              </div>
              <ChevronRightIcon className='size-6 text-buddy-text-3 grow-0' />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Security;