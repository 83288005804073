import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';

function RecetaInstrE({idreceta}){

  const [instrucciones, setInstrucciones] = useState([]);
  const [instruccionVisible, setInstruccionVisible] = useState([]);
  const [nuevoEncabezadoVisible, setNuevoEncabezadoVisible] = useState([]);
  const [nuevoParrafoVisible, setNuevoParrafoVisible] = useState([]);
  const [cadenaNew, setCadenaNew] = useState([]);
  
  const handleInstruccionClick = (e,ingr) => {
    //e.preventDefault();
    setNuevoEncabezadoVisible(0);
    setNuevoParrafoVisible(0);
    setInstruccionVisible(ingr);
    /*console.log(instrucciones);*/
  };

  const handleInstruccionSave = (id,cadena) => {
    setInstruccionVisible('');
    setNuevoEncabezadoVisible('');
    setNuevoParrafoVisible('');

      const requestOptions = {
        method: 'POST',
        redirect: 'manual',
        // headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify([{ id }, { cadena }])
      };
      console.log(requestOptions);
      fetch(`https://buddymarket.io/dummy/apibm/recipes/updateinstructions.php`, requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data);
        })
        .catch(error => console.error('Error updating instructions:', error));
    
  };

  const handleInstruccionAdd = (e,tipo,cadena,idreceta) => {
    setInstruccionVisible('');
    setNuevoEncabezadoVisible('');
    setNuevoParrafoVisible('');
        
      const requestOptions = {
        method: 'POST',
        redirect: 'manual',
        // headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify([{ tipo }, { cadena }, { idreceta }])
      };
      console.log(requestOptions);
      fetch(`https://buddymarket.io/dummy/apibm/recipes/addinstruction.php`, requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          setInstrucciones(data.data);
        })
        .catch(error => console.error('Error adding instruction:', error));
    
  };

  const setCadena = (e, id) => {
    const updatedInstrucciones = instrucciones?.map(instruccion => {
      if (instruccion.id === id) {
        return { ...instruccion, cadena: e.target.value };
      }
      return instruccion;
    });
    setInstrucciones(updatedInstrucciones);

  };

  const clearTextareaContent = (textareaId) => {
    document.getElementById(textareaId).value = ''; 
  };

  const handleCloseClick = (id,idreceta) => {
    
    const requestOptions = {
      method: 'POST',
      redirect: 'manual',
      // headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify([{ id }, { idreceta }])
    };
    fetch(`https://buddymarket.io/dummy/apibm/recipes/removeinstruction.php`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setInstrucciones(data.data);
      })
      .catch(error => console.error('Error fetching instructions:', error));
  };

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/recipes/readinstructions.php?id=${idreceta}`)
      .then(response => response.json())
      .then(data => {
        setInstrucciones(data.data);
      })
      .catch(error => console.error('Error fetching instructions:', error));
  }, [idreceta]);

  return (
    <>
    <div className='conborde'>
      {instrucciones?.map((instruccion) => (
        <div key={instruccion.id} className="w100">
          {instruccion.tipo == 1 ? (
            <>
            <h3 id={`element${instruccion.id}`} onClick={(e) => handleInstruccionClick(e,instruccion.id)} className={instruccionVisible === instruccion.id ? "oculto izquierda block w100 mb20" : "visible izquierda block w100 mb20"} >{instruccion.cadena}</h3>
            <div className={instruccionVisible === instruccion.id ? "visible input-container w100" : "oculto input-container w100 h0"}>
              <FontAwesomeIcon  icon={faXmarkCircle} className="iconright" onClick={(e) => handleCloseClick(instruccion.id,idreceta)} />
              <textarea rows='1' onChange={(e) => setCadena(e, instruccion.id)} onBlur={(e) => handleInstruccionSave(instruccion.id,instruccion.cadena)} className="w100 mb20" type="text" id="newinstruction" defaultValue={instruccion.cadena} />
            </div>
            </>
          ) : (
            <>
            <div onClick={(e) => handleInstruccionClick(e,instruccion.id)} className={instruccionVisible === instruccion.id ? "oculto flex izquierda vcentrado gap10 rgap20 mb20" : "visible flex izquierda vcentrado gap10 rgap20 mb20"}>
              {instruccion.cadena}
            </div>
            <div className={instruccionVisible === instruccion.id ? "visible input-container w100" : "oculto input-container w100 h0"}>
              <FontAwesomeIcon  icon={faXmarkCircle} className="iconright" onClick={(e) => handleCloseClick(instruccion.id,idreceta)} />
              <textarea onChange={(e) => setCadena(e, instruccion.id)} onBlur={(e) => handleInstruccionSave(instruccion.id,instruccion.cadena)} className="w100 mb20" type="text" rows="2" id="newinstruction" defaultValue={instruccion.cadena} />
            </div>
            </>
          )}
        </div>
      ))}
      
    </div>
    <div>
        <div className="input-container flex gap10 w100">
          <div className='p0 w80'>
            <textarea rows='1' className={nuevoEncabezadoVisible === 1 ? "visible w100 mt20" : "oculto h0 p0 b0"} 
            onChange={(e) => setCadenaNew(e)} onBlur={(e) => handleInstruccionAdd(e,1,e.target.value,idreceta)}
            type="text" id="newcabecera" placeholder="Encabezado"/>
            <textarea rows='2' className={nuevoParrafoVisible === 1 ? "visible w100 mb20" : "oculto h0 p0 b0"}
            onChange={(e) => setCadenaNew(e)} onBlur={(e) => handleInstruccionAdd(e,0,e.target.value,idreceta)}
            type="text" id="newparrafo" placeholder="Párrafo"/>
            {/*<input className="w100 mb20" type="text" id="newfoto" placeholder="Foto"/>*/}
          </div>
          <button type="submit" className={nuevoEncabezadoVisible === 1 || nuevoParrafoVisible === 1  ? "visible bordeblanco w10 mt20 mb20 f08 h42" : "oculto h0 p0 b0"}>
            <Link className="textoblanco">
              Añadir
            </Link>
          </button>
        </div>  
        <div className='flex equiespaciado gap10'>
          <button type="button" onClick={() => {setNuevoEncabezadoVisible(1);setNuevoParrafoVisible(0);setInstruccionVisible('');clearTextareaContent('newcabecera')}} 
          className="w30 action fondonaranja">
            <Link to="#" className="textoblanco" id="encabezadoinstrucciones">Encabezado</Link>
          </button>
          <button type="button" onClick={() => {setNuevoParrafoVisible(1);setNuevoEncabezadoVisible(0);setInstruccionVisible('');clearTextareaContent('newparrafo')}} 
          className="w30 action fondonaranja">
            <Link to="#" className="textoblanco" id="parrafoinstrucciones">Instrucción</Link>
          </button>
          {/*<button className="w30 action fondonaranja f08"><Link to="#" className="textoblanco" id="encabezadoinstrucciones">Foto</Link></button>*/}
          {/*<button><Link to="#" className="textonaranja" id="encabezadoinstrucciones">+ Video</Link></button>*/}
        </div>
      </div>
    </>
  )

};
export default RecetaInstrE;