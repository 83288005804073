import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import chef from "../img/chef.png";
import lista from "../img/lista.png";
import vegetales from "../img/vegetales.png";
import supermarket from "../img/supermarket.png";
import buddymarketia from "../img/buddyia.png"
import masreceta from "../img/masrecetaok.png";
import db_create_recipe_icon from "../img/db_create_recipe_icon.png";
import db_buddy_ia_icon from "../img/db_buddy_ia_icon.png";
import db_menu_scheduler_icon from "../img/db_menu_scheduler_icon.png";
import db_my_recipes_icon from "../img/db_my_recipes_icon.png";
import db_recipes_icon from "../img/db_recipes_icon.png";
import db_shopping_list_icon from "../img/db_shopping_list_icon.png";
import toolbar_coin from "../img/toolbar_coin.png";
import menus from "../img/menus-icon.png";
import { Link, useNavigate } from "react-router-dom";
import { isBuddyMaker, getUserInfo } from "../auth";
import Toolbar from "./ui/Toolbar";
import Input from "./forms/Input";
import FooterToolbar from "./ui/FooterToolbar";
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Bubble from "./ui/Bubble";
import { library } from "@fortawesome/fontawesome-svg-core";
import DashboardItem from "./ui/DashboardItem";

const Dashboard = () => {

  const [userAvatarUrl, setUserAvatarUrl] = useState('');
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');

  document.addEventListener('ionBackButton', (ev) => {
    ev.detail.register(10, () => {
      alert("backbutton");
    });
  });
  
  useEffect(() => {
    var user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      setUser(user);
      setUserAvatarUrl("https://ui-avatars.com/api/?name=" + user.name);
    }
  }, []);

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <div className='w-full h-12 flex justify-between space-x-4 text-title items-center text-lg mb-2'>
          <div className='rounded-full h-10 bg-white bg-opacity-20 flex flex-col justify-center items-center'>
            <Link to={'/sidebarMenu'} className="h-12 w-12">
              <div className="relative overflow-hidden h-12 w-12 rounded-full bg-black"> 
                <img src={user?.image_url} alt="" className="bg-black h-12 w-12 object-cover object-center" />
              </div>
            </Link>
          </div>
          <div className='grow text-center'>
            <div className="w-full h-10 bg-buddy-bg-1 rounded-xl relative shadow-inner ring-inset ring-gray-700 ring-1">
              <div className="absolute h-10 flex flex-row items-center">
                  <div type='button' className='w-8 ps-4'>
                    <FontAwesomeIcon className='text-white' icon={faSearch} />
                  </div>
              </div>
              <input autoComplete="new-password" aria-autocomplete='none' list="autocompleteOff" className="appearance-none w-full h-full pb-1 bg-transparent text-white ps-11 pe-2 placeholder:text-sm rounded-2xl"
                  name="" type="text" placeholder="Buscar en BuddyMarket"
              />
            </div>
          </div>
          <img className="w-12" src={toolbar_coin} />
        </div>
        <div className="w-full grid grid-cols-3 bg-red items-start gap-1 gap-y-8 pt-8">
          <DashboardItem label={"Lista de la Compra"} iconSrc={db_shopping_list_icon} to={'/shoppingLists'}></DashboardItem>
          <DashboardItem label={"Organizador de menús"} iconSrc={db_menu_scheduler_icon} to={'/menuOrganizerList'}></DashboardItem>
          <DashboardItem label={"Buddy IA"} iconSrc={db_buddy_ia_icon} to={''}></DashboardItem>
          <DashboardItem label={"Biblioteca de Recetas"} iconSrc={db_recipes_icon} to={'/recipes'}></DashboardItem>
          <DashboardItem label={"Mis Recetas"} iconSrc={db_my_recipes_icon} to={'/recipeList'}></DashboardItem>
          {
            user != null && user.role.id == 5 ? <DashboardItem label={"Crear Receta"} iconSrc={db_create_recipe_icon} to={'/recipeCreate'}></DashboardItem> : ''
          }
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
