import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import Select from './forms/Select';
import Helpbox from './forms/Helpbox';
import AgeRange from '../api/services/AgeRange';

const ProfileForm1 = () => {
  const navigate = useNavigate();

  const options = [
    {
      value: 1,
      label: "Comprador individual"
    },
    {
      value: 2,
      label: "Familia"
    }
  ];
  const [personsOptions, setPersonsOptions] = useState([]);
  const [personsAgesRanges, setPersonsAgesRanges] = useState([]);
  const [personsAgesRangesValues, setPersonsAgesRangesValues] = useState([]);
  const [disableBuyerNumPersons, setDisableBuyerNumPersons] = useState(true)
  const [buyerTypeId, setBuyerTypeId] = useState(1);
  const [buyerNumPersons, setBuyerNumPersons] = useState(1);
    
  const handleClick = (e) => {
    var ranges = [];
    var totalPersons = 0;
    personsAgesRanges.map(range => {
      const name = "buyer_persons_range_" + range.id;
      const value = parseInt(document.querySelector('[name="'+name+'"]').value);
      totalPersons += value;
      ranges.push({
        id: range.id,
        value: value
      })
    });

    if (totalPersons != buyerNumPersons) {
      alert("El número de personas indicados en los rangos de edades no coincide con el número de personas que conforman el hogar.");
      console.log(totalPersons + " " + buyerNumPersons)
    } else {
      var data = JSON.parse(localStorage.getItem('register_data'));
      data.buyer_type_id = buyerTypeId
      data.home_members = buyerNumPersons
      data.home_member_age_ranges = ranges;
      localStorage.setItem('register_data', JSON.stringify(data));
      navigate('/profileForm2');
    }  
  }

  const handleBuyerTypeChange = (value) => {
    setBuyerTypeId(value);
    updateBuyerNumPersonsControl(value);
  }

  const handleBuyerNumPersonsChange = (e) => {
    
    setBuyerNumPersons(e.target.value)
  }

  const handleOnChangeAgesRanges = (e) => {
    const values = personsAgesRangesValues.slice();
    values[e.target.name.split("_")[3] - 1] = parseInt(e.target.value);
    setPersonsAgesRangesValues(values);
  }

  const updateBuyerNumPersonsControl = (buyerTypeId) => {
    if (buyerTypeId == 1) {
      setBuyerNumPersons(1)
      setDisableBuyerNumPersons(true)
    } else {
      setDisableBuyerNumPersons(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  
    var personsOptions = [];
    for (var i = 1; i <= 10; i++) {
      personsOptions.push({
        value: i,
        label: i
      })
    }
    setPersonsOptions(personsOptions);

    loadPersonsAgesRanges();
  }, []);

  function loadStoredData() {
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setBuyerTypeId(data.buyer_type_id);
      setBuyerNumPersons(data.home_members);
      updateBuyerNumPersonsControl();
      var rangesValues = [];
        if (data.home_member_age_ranges) {
        data.home_member_age_ranges.map(range => {
          const name = "buyer_persons_range_" + range.id;
          const el = document.querySelector('[name="'+name+'"]');
          if (el) {
            el.value = range.value;
          }
          rangesValues[range.id - 1] = range.value
        })
      }
      setPersonsAgesRangesValues(rangesValues)
    }
  }

  async function loadPersonsAgesRanges() {
    try {
      const response = await AgeRange.list();
      var options = [];
      response.data.map(range => {
        options.push({
          id: range.id,
          name: range.name
        });
      });
      setPersonsAgesRanges(options);
      loadStoredData();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
        <form>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={-1}>
              <div className='text-sm'>
                <span >Paso 1</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>Tipo de comprador</h2>
            <SelectList onChange={handleBuyerTypeChange} selected={buyerTypeId} options={options} />
            <h2 className='w-full text-white text-xl font-semibold'>Indica el número de personas</h2>
            <Select value={buyerNumPersons} disabled={disableBuyerNumPersons} onChange={handleBuyerNumPersonsChange} key="home_members" name="home_members" options={personsOptions} />
            <h2 className='w-full text-white text-xl font-semibold'>Edades de las personas del Hogar</h2>
            <Helpbox text="Por favor, indica la cantidad de personas en cada rango de edad que viven en tu hogar." />
            
            <div className='w-full grid grid-cols-2 gap-4'>
              {
                personsAgesRanges.map(range => {
                    const name = "buyer_persons_range_" + range.id
                    return (
                      <Select key={name} name={name} placeholder={range.name} onChange={handleOnChangeAgesRanges} placeholderValue={0} value={personsAgesRangesValues[range.id - 1]} options={personsOptions} />
                    )
                  })
                }
            </div>
            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileForm1;