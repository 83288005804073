import { faBackward, faChevronCircleLeft, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'

const FooterToolbar = ({ children, to = '' }) => {
    return (
        <>
            <div className='w-full max-w-xl bottom-4 flex justify-between items-center pb-4 mt-4'>
                <div className='grow'>
                    {children}
                </div>
            </div>
        </>
    )
}

export default FooterToolbar 