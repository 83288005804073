import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEdit  } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const PerfilEdit = () => {
  const [usuario, setUsuario] = useState([]);
  const [result, setResult] = useState([]);
  const { id } = useParams();
  const placeholder = "nouser.png";
  const [file, setFile] = useState();
  const { register, formState , handleSubmit, watch, setValue, onChange } = useForm();
  const [genero, setGenero] = useState("");
  
  function fotopreview(e) {  
    setFile(URL.createObjectURL(e.target.files[0]))
  }
  function handleclick(e){
    document.getElementById('foto').click();
  }

  function clickOn(container) {
    console.log(container);
    const button = document.querySelector('#'+container);
    button.click();
  }

  const onOptionChange = (e) => {
    setGenero(e.target.value);
    const radioOptions = document.querySelectorAll('.radio-option');
    for (const option of radioOptions) {
      option.classList.remove('selected');
    }
    e.target.parentNode.classList.add('selected');
  }

  const onSubmit = (data) => {

    /*console.log(data['name']);*/

    const formData = new FormData();
    var input = document.querySelector('input[type="file"]');
    for (const file of input.files) {
      formData.append('photos',file,file.name);
    }
    formData.append("id", data['id']);
    formData.append("username", data['username']);
    formData.append("email", data['email']);
    formData.append("name", data['name']);
    formData.append("passwd", data['passwd']);
    formData.append("phone", data['phone']);
    formData.append("descripcion", data['descripcion']);
    formData.append("active", data['active']);
    formData.append("delete", data['delete']);
    formData.append("foto", data['foto']);

    const requestOptions = {
      method: 'POST',
      redirect: 'manual',
      /*headers: { 'Content-Type': 'application/json' },*/
      /*body: JSON.stringify(formData)*/
      body: formData
    };
    console.log(requestOptions);
    fetch('https://buddymarket.io/dummy/apibm/users/update.php',requestOptions)
      .then(response => response.json())
      .then(data => {
        setResult(data.data); 
        console.log(result);
        //navigate(paginaperfil);
      })
      .catch(error => console.error('Error updating user:', error));

  }

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/users/read.php/?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setUsuario(data.data);
        setGenero(data.data[0].genero);
      })
      .catch((error) => console.error("Error fetching usuario:", error));
  }, []);


  return (
  <div className="contenedor">
    <div className="titulin">
      <Link to={-1}>
            <span className="simbologran">&lt;</span>
      </Link>
      <h2 className="cuerpotexto">Editar Perfil{id}</h2>
    </div>
    <form className="izquierda" id="principalreceta" onSubmit={ handleSubmit(onSubmit)}>
      {usuario.map(user => (
      <div key={user.id}>

      <div className="rectangulo centrado round mt0">
        
        <label className="izquierda w100 mb10" htmlFor="username">Nombre de usuario</label><br />
        <div className="input-container"> 
          <FontAwesomeIcon icon={faUser} className="iconleft" />
          <input className="w100 mb20" type="text" name="username" 
          {...register('username', {value:user.username}, {required: true})} />
          <br />{formState.errors.username?.type === 'required' && <p className='titulin naranja mb10'>El campo es requerido</p>}
        </div>

        <label className="izquierda w100 mb10" htmlFor="email">Correo electrónico</label><br />
        <div className="input-container"> 
          <FontAwesomeIcon icon={faEnvelope} className="iconleft" />
          <input className="w100 mb20" type="text" name="email" 
          {...register('email', {value:user.email}, {required: true})} />
          <br />{formState.errors.email?.type === 'required' && <p className='titulin naranja mb10'>El campo es requerido</p>}
        </div>

        <label className="izquierda w100 mb10" htmlFor="phone">Número de Teléfono</label><br />
        <div className="input-container"> 
          <FontAwesomeIcon icon={faPhone} className="iconleft" />
          <input className="w100 mb20" type="text" name="phone" 
          {...register('phone', {value:user.phone}, {required: true})} />
          <br />{formState.errors.phone?.type === 'required' && <p className='titulin naranja mb10'>El campo es requerido</p>}
        </div>

        <label className="izquierda w100 mb10" htmlFor="descripcion">Biografía</label><br />
        <div className="input-container"> 
          <textarea rows="5" className="w100 mb20" type="text" name="descripcion" 
          {...register('descripcion', {value:user.descripcion}, {required: true})} />
          <br />{formState.errors.descripcion?.type === 'required' && <p className='titulin naranja mb10'>El campo es requerido</p>}
        </div>
        
       
        <label className="izquierda w100 mb10">Género</label><br />
          <div className="radio-container center nowrap">
            <div className={(genero === "hombre")?'radio-option checked selected':'radio-option checked'} onClick = {() => clickOn('hombre')} >
              <input
                  {...register("genero",{value:user.genero})}                 
                  type="radio"
                  name="genero"
                  value="hombre"
                  id="hombre"
                  checked={genero === "hombre"}
                  onChange={(e) => onOptionChange(e)}
              />
              <label>Hombre</label>
            </div>
            <div className={(genero === "mujer")?'radio-option checked selected':'radio-option checked'} onClick = {() => clickOn('mujer')}>
              <input
                  {...register("genero",{value:user.genero})}
                  className='radio-option'
                  type="radio"
                  name="genero"
                  value="mujer"
                  id="mujer"
                  checked={genero === "mujer"}
                  onChange={(e) => onOptionChange(e)}
              />
              <label>Mujer</label>
            </div>
          </div>
          <br />
                 
          <label className="izquierda w100 mb10">Redes Sociales</label><br />
          <div>
            <div className="input-container">
              <FontAwesomeIcon icon={faInstagram} className="iconleft" />
              <FontAwesomeIcon icon={faEdit} className="iconright" />
              <input className="w100 mb20" type="text" name="instagram" />
            </div>
            <div className="input-container">
              <FontAwesomeIcon icon={faYoutube} className="iconleft" />
              <FontAwesomeIcon icon={faEdit} className="iconright" />
              <input className="w100 mb20" type="text" name="youtube" />
            </div>
            <div className="input-container">
              <FontAwesomeIcon icon={faTiktok} className="iconleft" />
              <FontAwesomeIcon icon={faEdit} className="iconright" />
              <input className="w100 mb20" type="text" name="tiktok" />
            </div>
          </div>



          <br />
          <div className="selectcontainer input-container">
            <input
              className="w8 mb20"
              type="checkbox"
              name="aceptocomunic"
              {...register('aceptocomunic', {value:user.aceptocomunic})}
            />
            
            <label className="izquierda w100" htmlFor="aceptocomunic">
              Acepto recibir comunicaciones y/o newsletter con información
              acerca de los servicios de BuddyMarket {/*conforme a su 
               <Link to="../terminos">Política de Privacidad</Link>*/}
            </label>
          </div>
        </div>
          <br />

      </div>
      ))}
       <div className="">
          <button className="w100 submit fondonaranja" type="button">
            <Link className="textoblanco" to={"../dashboard"}>Guardar perfil</Link>
          </button>
        </div>
    </form>
      
  </div>
  )
};

export default PerfilEdit;
