import React, {useState, useEffect} from 'react'
import Recipe from '../../api/services/Recipe';
import { Link } from 'react-router-dom';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ClockIcon, ReceiptPercentIcon } from '@heroicons/react/24/outline';
import Badge from './Badge';

const RecipeTile = ({ recipe, to = null, showStatus = false, showAuthor = true }) => {

    const [img, setImg] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        /*if (!recipe.image_url) {
            setLoading(true);
            getImage();
        }*/
        
    }, []);
    
    const getImage = async () => {
        try {
            const response = await Recipe.image(recipe.id);
            const blob = await response.blob();
            setImg(URL.createObjectURL(blob));
        } catch (error) {
            console.error('Error fetching recipes:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Link to={to} className="relative w-full grid grid-cols-3 gap-4">
                <div className="relative">
                    <div className="w-full rounded-lg">
                        {
                            loading ?
                            <div className="bg-black h-28 w-28 object-cover flex items-center justify-center rounded-lg"><FontAwesomeIcon icon={faCircleNotch} className='animate-spin text-white' /></div>
                            :
                            <img src={recipe.image_url} alt="" className="h-28 w-28 object-cover object-center rounded-lg" />
                        }
                    </div>
                </div>
                <div className='bottom-3 col-span-2 text-buddy-text-1 '>
                    <h2 className='text-md font-semibold'>{recipe.name}</h2>
                    {
                        recipe.user && showAuthor ? 
                        <h3 className='text-sm'>Autor: {recipe.user.name }</h3> : ''
                    }
                    <div className='flex text-xs space-x-1 mt-1'><ClockIcon className='size-4' /> <span>Preparación: { recipe.preparation_time } min</span></div>
                    <div className='flex text-xs space-x-1 mt-1'><ClockIcon className='size-4' /> <span>Cocción: { recipe.cook_time } min</span></div>

                    
                    
                </div>
                <div className='absolute bottom-0 right-0'>
                    {
                        showStatus ? ( recipe.active ? <Badge type='primary'>Pública</Badge> :  <Badge>Borrador</Badge>) : ''
                    }
                </div>
            </Link>
        </>
    )
}

export default RecipeTile 