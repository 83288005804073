import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEdit, faY } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, fainst } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import FooterMain from "./FooterMain";

const Perfil = () => {
  const [usuario, setUsuario] = useState([]);
  const [recetas, setRecetas] = useState([]);
  const { id } = useParams();
  const placeholder = "nouser.png";

  useEffect(() => {
    fetch(
      `https://buddymarket.io/dummy/apibm/recipes/readlistbuddy.php?id=${id}`
    )
      .then((response) => response.json())
      .then((data) => {
        setRecetas(data.data);
      })
      .catch((error) => console.error("Error fetching recipes:", error));
  }, []);

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/users/read.php/?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setUsuario(data.data);
      })
      .catch((error) => console.error("Error fetching usuario:", error));
  }, []);

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={'../dashboard'}>
              <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Perfil</h2>
      </div>

      <div className="rectangulo round relative fondouno">
        {usuario?.map((usu) => (
          <div key={usu.id} className="w100">
            <h2 className="blanconegrita centrado f18">{usu.name}</h2>
            <div className="flex wrap equiespaciado rgap20 vtop">
              <div key={usu.id} className="w20 vtop textoblanco buddys">
                <img
                  className="w90"
                  src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/users/${usu.foto || placeholder}`}
                  alt={usu.username}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div className="redes textoblanco w80">
                <div className="w100">&nbsp;&nbsp;<Link to=""><FontAwesomeIcon icon={faInstagram} className="f16 w20" /> <span className=" textoblanco">&nbsp;Cuenta Instagram&nbsp;</span></Link></div>
                <div className="w100">&nbsp;&nbsp;<Link to=""><FontAwesomeIcon icon={faYoutube} className="f14 w20" /> <span className=" textoblanco">&nbsp;Cuenta Youtube&nbsp;</span></Link></div>
                <div className="w100">&nbsp;&nbsp;<Link to=""><FontAwesomeIcon icon={faTiktok} className="f14 w20" /> <span className=" textoblanco">&nbsp;Cuenta Tiktok&nbsp;</span></Link></div>
                <div className="w100">&nbsp;&nbsp;<Link to=""><FontAwesomeIcon icon={faLink} className="f14 w20" /> <span className=" textoblanco">&nbsp;Website</span></Link></div>

              </div>
              <div className="flex equiespaciado gap10">
                <button className="bordeblanco mb10 f09 h42">
                  <Link 
                    className="textoblanco" 
                    to={`../editar-perfil/${id}`}
                  >
                    Editar perfil
                  </Link>
                </button>
                <button className="bordeblanco f09 h42">
                  <Link
                    className="textoblanco"
                    to={`../preferencias/${id}`}
                  >
                    Editar preferencias
                  </Link>
                </button>
              </div>
            </div>
            
            <div className="w100 centrado">
              <hr />
              {usu.descripcion}
            </div>
          </div>
        ))}
      </div>
      <h2 className="izquierdo">Recetas creadas</h2>
      {recetas?.map((receta) => (
        <div key={receta.id} className="rectangulo round relative fondoblanco">
          <div className="flex wrap izquierda rgap20 vtop">
            <div className="w40 spacebetween">
              <a href={`../receta/${receta.id}`}>
                <img
                  className="w100 mb10"
                  src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${receta.photo}`}
                  alt={receta.name}
                />
              </a>
              <br />
              <div className="w100 flex spacebetween">
                <Link to={`../editar-receta/${receta.id}`}><FontAwesomeIcon icon={faEdit} className="f14" /></Link>
                <Link to={`../borrar-receta/${receta.id}`}><FontAwesomeIcon icon={faTrash} className="f14" /></Link>
                </div>
              </div>
            <div className="w60 pl20">
              <a href={`../receta/${receta.id}`}>
                <h2 className="textooscuro">{receta.name}</h2>
              </a>
              <p className="rojo w100 mt10 centrado">{(receta.active==1)?null:'No publicada'}</p>
              <br />
              <span>{receta.description}</span>
            </div>
          </div>
        </div>
      ))}
      <br />
      <br />

    </div>
  );
};

export default Perfil;
