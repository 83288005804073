import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import logobuddy from '../img/logo-naranja.png';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const RecCont3 = () => {

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Restablecer Contraseña</h2>      
      </div>
      <div className="rectangulo centrado fondouno round mb20">
        <h1>No compartas tus contraseñas con nadie</h1>
        <p>Por favor, introduce tu nueva contraseña</p>
      </div>
      <form className="izquierda" action="recuperar-contrasena-fin">
      <div className="rectangulo centrado round">
        <label className="izquierda w100 mb10" htmlFor="codigo">Introduce tu nueva contraseña</label><br />
        <div className="input-container"> 
          <FontAwesomeIcon icon={faLock} className="icon" />
          <input className="w100 mb20" type="password" name="email" placeholder="Contraseña" /><br />
        </div>
        <div className="izquierda">
            <ul>
                <li>
                <span className='relative'><FontAwesomeIcon icon={faCheck} className="icon" /></span> Al menos 8 caracteres
                </li>
                <li>
                <span className='relative'><FontAwesomeIcon icon={faCheck} className="icon" /></span> Contiene un número
                </li>
                <li>
                <span className='relative'><FontAwesomeIcon icon={faCheck} className="icon" /></span> Contiene un caracter especial
                </li>
            </ul>
            <br />
        </div>
        <label className="izquierda w100 mb10" htmlFor="codigo">Repetir contraseña</label><br />
        <div className="input-container"> 
          <FontAwesomeIcon icon={faLock} className="icon" />
          <input className="w100 mb20" type="password" name="email" placeholder="Contraseña" /><br />
        </div>
      </div>
      <div className="">
          <button className="w100 submit fondonaranja" type="submit">Enviar</button>
        </div>
      </form>
      <div className="contenedorabajo centrado">
        <img className="w60 inlineblock" src={logobuddy} alt="Logo BuddyMarket" />
      </div>

    </div>
  );
};

export default RecCont3;