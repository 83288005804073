import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { isAuthenticated } from '../auth';

function ProtectedRoute({ children }) {

    const isAuth = isAuthenticated(); 
    
  if (isAuth == 'false') {
    return <Navigate to="/" replace />;
  }

  /*return <Route {...rest} element={<Element />} />;*/
  return children
}

export default ProtectedRoute;