import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import SelectList from './forms/SelectList';
import FooterToolbar from './ui/FooterToolbar';
import PrimaryButton from './ui/PrimaryButton';
import DietRestriction from '../api/services/DietRestriction';
import CheckBoxSelect from './forms/CheckBoxSelect';

const ProfileForm7 = () => {
  const navigate = useNavigate();

  const [dietRestrictionIds, setDietRestrictionIds] = useState([]);
  const [dietRestrictionOptions, setDietRestrictionOptions] = useState([]);

  const handleClick = (e) => {
    const data = JSON.parse(localStorage.getItem('register_data'));
    data.diet_restriction_ids = dietRestrictionIds;
    localStorage.setItem('register_data', JSON.stringify(data));
    navigate('/profileForm8');
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const data = JSON.parse(localStorage.getItem('register_data'));
    if (data != null) {
      setDietRestrictionIds(data.diet_restriction_ids ?? []);
    }
    loadDietRestrictions();
  }, []);

  const onSelectionChange = (selection) => {
    setDietRestrictionIds(selection);
  }

  async function loadDietRestrictions() {
    try {
      const response = await DietRestriction.list();
      var options = [];
      response.data.map(restriction => {
        options.push({
          value: restriction.id,
          label: restriction.name
        });
      });
      setDietRestrictionOptions(options);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className='h-screen bg-no-repeat bg-cover bg-center'>
      <div className='h-screen bg-secondary-bg bg-opacity-60 p-6'>
          <div className="flex flex-col bg-red items-center gap-3">
            <Toolbar to={-1}>
              <div className='text-sm'>
                <span >Paso 7</span> <span className='text-primary'>de 28</span>
              </div>
            </Toolbar>
            <h2 className='w-full text-white text-xl font-semibold'>Restricciones en tu dieta</h2>
            <CheckBoxSelect selection={dietRestrictionIds} name="diet_restriction_ids" onChange={onSelectionChange} options={dietRestrictionOptions} />

            <FooterToolbar>
              <PrimaryButton type="button" onClick={handleClick} >Siguiente</PrimaryButton>
            </FooterToolbar>
          </div>
        
      </div>
    </div>
  );
};

export default ProfileForm7;