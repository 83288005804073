import React, { useState, useEffect } from 'react';
import logobuddy from '../img/b-de-buddy.png';
import bgLogin from '../img/bg-login.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom"; 
import { useNavigate } from 'react-router-dom';
import applelogo from "../img/Apple_logo_black.svg.png";
import facebooklogo from "../img/facebook_icon.png";
import PrimaryTextLink from './ui/PrimaryTextLink';
import PrimaryButton from './ui/PrimaryButton';
import Card from './ui/Card';
import Auth from '../api/services/Auth';
import LoginInput from './forms/LoginInput';


async function loginUser(credentials) {
  return fetch('https://app.buddymarket.io/api/login',{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  }).then(response => {
    if (response.status !== 200) {
      return false;
    }
    return response.json();
  });
}

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const navigate = useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();
  
    try {
      const response = await Auth.login({email, password});
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      await getProfile();
    } catch (error) {
      console.log(error);
      if (error.code === 403) {
        // TODO: Mostrar aviso de usuario o contraseña incorrecto
        alert('Usuario o contraseña incorrecto');
      }
    }
  }

  const getProfile = async () => {
    try {
      const response = await Auth.profile();
      localStorage.setItem('user', JSON.stringify(response.data));
      navigate('/');
    } catch (error) {
      if (error.code === 401) {
        navigate('login', {replace: true});
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    const user = JSON.parse(localStorage.getItem('user'));
    if (user != null) {
      navigate('/', { replace: true});
    }
    const el = document.querySelector('[name="email"]');
    if (el) {
      el.focus();
    }
  }, []);

  const bgImageStyle = {
    backgroundImage: `url(${bgLogin})`,
  }

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center' style={bgImageStyle}>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <form className="w-full" onSubmit={handleSubmit} >
          <div className="flex flex-col bg-red items-center gap-6">
            <img className="w-2/5 z-10 " src={logobuddy} alt="logo BuddyMarket" />
            <Card className="pt-14 -mt-16 bg-primary-bg">
              <h1 className='font-title text-2xl text-white font-bold mb-4 text-center'>Bienvenid@ a BuddyMarket</h1>
              <p className='text-md px-8 text-center text-body font-light'>Donde tus gustos y nuestra inteligencia artificial cocinan juntos la lista de compras perfecta.</p>
            </Card>
            
            <div className='w-full flex flex-col gap-4 '>
              <LoginInput label="Usuario" type="text" name="email" placeholder="Correo electrónico" onChange={e => setEmail(e.target.value)}>
                <FontAwesomeIcon icon={faEnvelope} className="w-5 h-5 text-login-text-color" />
              </LoginInput>

              <LoginInput label="Contraseña" type="password" name="password" placeholder="Password" onChange={e => setPassword(e.target.value)}>
                <FontAwesomeIcon icon={faLock} className="w-5 h-5 text-login-text-color" />
              </LoginInput>

              <div className="text-right">
                <Link className="text-primary" to="">¿Has olvidado tu contraseña?</Link>
              </div>
            </div>
            
            <div className="w-full mt-2 mb-4">
              <PrimaryButton type="submit">Iniciar sesión</PrimaryButton>
              <div className='my-4'></div>
              <PrimaryTextLink to="/SignUp">Registrarse</PrimaryTextLink>
            </div>

            { false &&
            <div className='flex flex-col w-full space-y-4'>
              <button type='button'  onClick={ (e) => { alert('No disponible') } } className='flex text-buddy-text-1 text-lg justify-center font-semibold items-center space-x-2 bg-black w-full px-4 py-4 rounded-xl'>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.55 20.28C16.57 21.23 15.5 21.08 14.47 20.63C13.38 20.17 12.38 20.15 11.23 20.63C9.78998 21.25 9.02998 21.07 8.16998 20.28C3.28998 15.25 4.00998 7.59 9.54998 7.31C10.9 7.38 11.84 8.05 12.63 8.11C13.81 7.87 14.94 7.18 16.2 7.27C17.71 7.39 18.85 7.99 19.6 9.07C16.48 10.94 17.22 15.05 20.08 16.2C19.51 17.7 18.77 19.19 17.54 20.29L17.55 20.28ZM12.53 7.25C12.38 5.02 14.19 3.18 16.27 3C16.56 5.58 13.93 7.5 12.53 7.25Z" fill="white"/>
                </svg>
                <span>Continuar con Apple</span>  
              </button>
              <button type='button' onClick={ (e) => { alert('No disponible') } } className='flex text-black text-lg justify-center font-semibold items-center space-x-2 bg-white w-full px-4 py-4 rounded-xl'>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.0587 12.16C21.0587 11.4167 20.997 10.8742 20.8634 10.3116H12.1793V13.6669H17.2767C17.1739 14.5007 16.619 15.7565 15.3857 16.6003L15.3684 16.7126L18.1142 18.7919L18.3044 18.8104C20.0515 17.2332 21.0587 14.9126 21.0587 12.16Z" fill="#4285F4"/>
                  <path d="M12.1792 21.0007C14.6765 21.0007 16.773 20.1969 18.3043 18.8107L15.3856 16.6005C14.6045 17.1329 13.5563 17.5046 12.1792 17.5046C9.73321 17.5046 7.65725 15.9275 6.91723 13.7475L6.80875 13.7565L3.95366 15.9164L3.91632 16.0179C5.43732 18.9713 8.56157 21.0007 12.1792 21.0007Z" fill="#34A853"/>
                  <path d="M6.91723 13.7475C6.72197 13.1849 6.60897 12.5821 6.60897 11.9593C6.60897 11.3365 6.72197 10.7337 6.90696 10.1712L6.90179 10.0513L4.01091 7.85678L3.91633 7.90076C3.28945 9.12637 2.92975 10.5027 2.92975 11.9593C2.92975 13.416 3.28945 14.7922 3.91633 16.0178L6.91723 13.7475Z" fill="#FBBC05"/>
                  <path d="M12.1792 6.41382C13.916 6.41382 15.0875 7.14717 15.7556 7.76001L18.3659 5.26862C16.7628 3.81197 14.6765 2.91788 12.1792 2.91788C8.56157 2.91788 5.43732 4.94713 3.91632 7.9006L6.90695 10.171C7.65725 7.99105 9.73321 6.41382 12.1792 6.41382Z" fill="#EB4335"/>
                </svg>
                <span>Continuar con Google</span>  
              </button>
            </div>
            }

          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
