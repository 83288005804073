import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import logobuddy from '../img/logo-naranja.png';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const RecCont2 = () => {

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Restablecer Contraseña</h2>      
      </div>
      <div className="rectangulo centrado fondouno round mb20">
        <h1>Consulta tu correo electrónico</h1>
        <p>Hemos enviado el código a tu correo electrónico.</p>
      </div>
      <form className="izquierda" action="recuperar-contrasena3">
      <div className="rectangulo centrado round">
        <label className="izquierda w100 mb10" htmlFor="codigo">Introduce el código</label><br />
        <div className="codcontainer equiespaciado">
            <input className="cod" maxlenght="1" size="1" type="number" pattern="[0-9]{1}" name="cod1" />
            <input className="cod" maxlenght="1" size="1" type="number" pattern="[0-9]{1}" name="cod2" />
            <input className="cod" maxlenght="1" size="1" type="number" pattern="[0-9]{1}" name="cod3" />
            <input className="cod" maxlenght="1" size="1" type="number" pattern="[0-9]{1}" name="cod4" />
        </div>
      </div>
      <div className="">
          <button className="w100 submit fondonaranja" type="submit">Continuar</button>
        </div>
      </form>
      <div className="contenedorabajo centrado">
        <img className="w60 inlineblock" src={logobuddy} alt="Logo BuddyMarket" />
      </div>

    </div>
  );
};

export default RecCont2;