import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faBed, faBell, faEdit  } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { faShield } from '@fortawesome/free-solid-svg-icons';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { faSpellCheck } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const Preferencias = () => {
  const [usuario, setUsuario] = useState([]);
  const [result, setResult] = useState([]);
  const { id } = useParams();
  const placeholder = "nouser.png";
  const [file, setFile] = useState();
  const placecholderUsuario = "nouser.png";

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/users/read.php/?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setUsuario(data.data);
      })
      .catch((error) => console.error("Error fetching usuario:", error));
  }, []);

  return (
  <div className="contenedor relative">
    <div className="titulin">
      <Link to={-1}>
            <span className="simbologran">&lt;</span>
      </Link>
      <h2 className="cuerpotexto">Editar Perfil</h2>
    </div>
    <div className="rectangulo round mt0">
    {usuario?.map(({ id, username, name, email, foto }) => (
      <div>
      <div key={id} className="flex equiespaciado">
      <div className="w20">
            <a className="w14 gap10" href={`perfil/${id}`}>
              <img
                className="usuario w80"
                src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/users/${
                  foto || placecholderUsuario
                }`}
                alt={username}
              />
              </a>
        </div>
        <div className="w70 mt0 textoblanco">
          <br />
          {name} <a href={`../editar-perfil/${id}`}><FontAwesomeIcon icon={faEdit} className="iright" /> </a>
        </div>
        </div>        
        <div className="mt40">
                Calendario de pago & métodos de pago
        </div>        
        <div className="textoblanco mt20">
          <FontAwesomeIcon icon={faCalendar} className="ileft" />&nbsp;&nbsp;
          Suscripción
        </div>
        <div className="textoblanco mt20">
          <FontAwesomeIcon icon={faWallet} className="ileft" />&nbsp;&nbsp;
          Métodos de pago
          <span className="iright f20 mt-10">&gt;</span>
        </div>

        <div className="mt30">
                Seguridad de la cuenta
        </div>        
        <div className="textoblanco mt20">
        <Link to="../" className="">
          <FontAwesomeIcon icon={faExternalLink} className="ileft textoblanco" />&nbsp;&nbsp;
          <span class="naranja">Cerrar Sesión</span>
          <span className="iright f20 mt-10">&gt;</span>
          </Link>
        </div>
        <div className="textoblanco mt20">
          <FontAwesomeIcon icon={faShield} className="ileft" />&nbsp;&nbsp;
          Seguridad
          <span className="iright f20 mt-10">&gt;</span>
        </div>

        <div className="mt30">
                Configuración de la APP
        </div>        
        <div className="textoblanco mt20">
          <FontAwesomeIcon icon={faClipboard} className="ileft" />&nbsp;&nbsp;
          Idioma
          <span className="iright f20 mt-10">&gt;</span>
        </div>
        <div className="textoblanco mt20">
          <FontAwesomeIcon icon={faBell} className="ileft" />&nbsp;&nbsp;
          Notificaciones
          <span className="iright f20 mt-10">&gt;</span>
        </div>

        <div className="mt30">
                Centro de ayuda
        </div>        
        <div className="textoblanco mt20">
          <FontAwesomeIcon icon={faSpellCheck} className="ileft" />&nbsp;&nbsp;
          Chat en línea
          <span className="iright f20 mt-10">&gt;</span>
        </div>
       
      </div>

    ))}
    </div>
  </div>
  )
};

export default Preferencias;
