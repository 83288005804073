import React from 'react';
import { useState, useEffect  } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom"; 
import FooterMain from './FooterMain';

const Buddymakers = () => {

  const [buddymaker, setBuddymaker] = useState([]);
  const [budname, setBudname] = useState('');
  const [recetas, setRecetas] = useState([]);
  const { id } = useParams();
 
  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/recipes/readlistbuddy.php?id=${id}`)
      .then(response => response.json())
      .then(data => {
        setRecetas(data.data);
      })
      .catch(error => console.error('Error fetching recipes:', error));
  }, []);

  useEffect(() => {
    fetch(`https://buddymarket.io/dummy/apibm/users/read.php/?id=${id}`)
      .then(response => response.json())
      .then(data => {
        setBuddymaker(data.data);
        setBudname(data.data[0].username);
        console.log(data.data[0].username);
        console.log(budname);
      })
      .catch(error => console.error('Error fetching buddymaker:', error));
  }, []);


  return (
  <div className="contenedor">
     <div className="titulin">
        <Link to={-1}>
            <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">BuddyMaker</h2>      
    </div>
    <div className="rectangulo centrado busqueda">
      <form id="busqueda" action="buscar">
        <div className="input-container">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="icon2" />
          <input className="w100" type="text" name="user" placeholder="Buscar" /><br />
        </div>
      </form>
    </div>
    <div className="rectangulo round relative fondouno">
        <div className='flex wrap equiespaciado rgap20 vtop'>
        {buddymaker?.map(buddy => (  
          <div key={buddy.id} className="w100 vtop">
          <img className="w20" src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/users/${buddy.foto}`} alt={buddy.username} /> &nbsp;&nbsp;&nbsp;&nbsp;
            <span className="blanconegrita f18">{buddy.username}</span>
          </div>
        ))}
        </div>
    </div>
    {recetas?.map(receta => (  
    <div key={receta.id} className="rectangulo round relative fondoblanco">
        <div className='flex wrap izquierda rgap20 vtop'>
            <div className='w40'>
              <a href={`../receta/${receta.id}`}>
                <img className="w100" src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/recetas/${receta.photo}`} alt={receta.name} />
                </a>
            </div>
            <div className='w60 pl20'>
              <a href={`../receta/${receta.id}`}><h2 className='textooscuro'>{receta.name}</h2></a>
              <br />
              <span>por <strong>{budname}</strong></span>            
            </div>
        </div>
    </div>
    ))}
    <br /><br />
    <FooterMain />
  </div>);
};

export default Buddymakers;