import client from "../client";

export default {
  list: (params) => client.get("users", {searchParams: params}).json(),
  listBuddMakers: () => client.get("users?role_id=5").json(),
  get: (id) => client.get(`users/${id}`).json(),
  update: (id, data) => client.post(`users/${id}`, {json: data}).json(),
  updatePassword: (id, data) => client.post(`users/${id}/password`, {json: data}).json(),
  updateEmail: (id, data) => client.post(`users/${id}/email`, {json: data}).json(),
  createAuthToken: (id) => client.post(`users/${id}/authTokens`).json(),
  image: (id) => client.get(`users/${id}/image`),


  favoriteRecipesList: (id, params) => client.get(`users/${id}/favoriteRecipes`, {searchParams: params}).json(),
  recipeList: (id) => client.get(`users/${id}/recipes`).json(),
  recipeGet: (user, recipe) => client.get(`users/${user}/recipes/${recipe}`).json(),
};