import React, { useState, useEffect  } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom"; 
import { useNavigate } from 'react-router-dom';
import applelogo from "../img/Apple_logo_black.svg.png";
import { isAuthenticated } from '../auth';
import { getUserInfo } from '../auth';
import FooterMain from './FooterMain';

const ListaDeLaCompraNueva = () => {

  const [list, setList] = useState([]);
  const [newlistItem, setNewlistItem] = useState([]);
  const [idList, setIdList] = useState('');
  const userinfo = getUserInfo();
  const iduser = userinfo.id;
  const navigate = useNavigate();
  const placecholderIcono = 'carrito-compra.png';

  async function manejarClick(lis){

    return fetch(`https://buddymarket.io/dummy/apibm/lists/create.php/`,{
      method: 'GET',
    })
    .catch(error => console.error('Error updating element:', error));
  }

  return (  
    
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Nueva lista de la compra manual</h2>      
      </div>
      
      <div className="rectangulo centrado busqueda">
        <form id="busqueda" action="buscar">
          <div className="input-container">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="icon2" />
            <input className="w100" type="text" name="user" placeholder="Buscar en BuddyMarket" /><br />
          </div>
        </form>
      </div>

      <div className="rectangulo round mb20 pb10 relative fondooscuro">
        <div className='flex wrap vcentrado centrado'>  
          {(!!list)?null:'Esta lista está vacía'}
          {list?.map(lis => (
            <div key={lis.id} className={(lis.completado==1)?`tachado listacompralista2 flex spacebetween w100 mb10 vcentrado`:`listacompralista2 flex spacebetween w100 mb10 vcentrado`}>
            <span className="w100"><img className='icono-lista vcentrado' width='52px' src={`https://buddymarket.io/dummy/buddymarket-app/imagenes/iconos/${lis.icono || placecholderIcono}`} /> {lis.id} {lis.completado} {lis.cadena}</span>
            <button className="botonselectlista" onClick={() => manejarClick(lis)}><span className="simbologran w20 derecha" >{(lis.completado==0)?String.fromCharCode(9711):String.fromCharCode(10754)}</span></button>
            </div>
          ))}
        </div>
      </div>
      <br /><br />
      <FooterMain />
    </div>

  );
}

export default ListaDeLaCompraNueva;

