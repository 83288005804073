import React, { useEffect, useState } from 'react';
import {useNavigate } from 'react-router-dom';
import Toolbar from './ui/Toolbar';
import FooterToolbar from './ui/FooterToolbar';
import Gender from '../api/services/Gender';
import { Link } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { LockClosedIcon, EnvelopeIcon  } from '@heroicons/react/24/outline'
import DangerButton from './ui/DangerButton';
import PasswordWithValidationInput from './forms/PasswordWithValidationInput'
import Input from './forms/Input';
import AuthTokenInput from './forms/AuthTokenInput';
import PrimaryButton from './ui/PrimaryButton';
import User from '../api/services/User';

const UserUpdateEmail = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));
  const [authTokenRequested, setAuthTokenRequested] = useState(false);
  const [authTokenCode, setAuthTokenCode] = useState(null);

  const [currentEmail, setCurrentEmail] = useState('');
  const [email, setEmail] = useState('');
  const [emailRepeat, setEmailRepeat] = useState('');

  const handleClick = async (e) => {
    if (authTokenRequested) {
      // Go update
      try {
        await User.updateEmail(user.id, {
          code: authTokenCode,
          current_email: currentEmail,
          email: email,
        });
        navigate('/userUpdateEmailSuccess');
      } catch (error) {
        console.log(error);
        alert("Ocurrió un error al intentar actualizar el correo electrónico.");
      }
    } else {
      const email = document.querySelector('[name="email"]').value;
      const emailRepeat = document.querySelector('[name="email_repeat"]').value;
      const currentEmail = document.querySelector('[name="current_email"]').value;
      if (validateEmail(email, emailRepeat)) {
        setEmail(email);
        setCurrentEmail(currentEmail);
        try {
          await User.createAuthToken(user.id);
          setAuthTokenRequested(true);
        } catch (error) {
          console.log(error);
          alert("Ocurrió un error al intentar actualizar el correo electrónico.");
        }
      } else {
        alert("Datos no válidos. Comprueba que los correos electrónicos coincidan.");
      }
    }
  }

  useEffect(() => {
    const el = document.querySelector('[name="current_email"]');
    if (el) {
      el.focus();
    }
  }, []);

  const validateEmail = (email, emailRepeat) => {
    if (email === emailRepeat) {
      return true;
    }
    return false;
  }
  
  const handleCodeChange = (value) => {
    setAuthTokenCode(value);
  }

  return (
    <div className='h-full bg-no-repeat bg-cover bg-center'>
      <div className='h-full bg-secondary-bg bg-opacity-60 p-6'>
        <div className="flex flex-col bg-red items-center gap-3">
          <Toolbar to={-1}>
            <div className='text-md'>Cambiar correo electrónico</div>
          </Toolbar>
          <div className='mt-6'></div>
          {
            authTokenRequested ?
            <div className='w-full'>
              <h2 className='text-buddy-text-1 text-center text-lg font-bold my-4'>Consulta tu correo electrónico</h2>
              <p className='text-buddy-text-2 text-center'>Hemos enviado un código de confirmación a tu correo electrónico</p>
              <div className='my-8'></div>
              <AuthTokenInput onChange={handleCodeChange} />
            </div>
            :
            <div className='w-full flex flex-col space-y-3'>
              <Input name='current_email' type='email' label="Introduce tu correo electrónico actual" />
              <Input name='email' type='email' label="Introduce tu nuevo correo electrónico" />
              <Input name='email_repeat' type='email' label="Confirma tu nuevo correo electrónico" />
            </div>
          }
        </div>
        <FooterToolbar>
          <PrimaryButton type="button" onClick={handleClick}>{ authTokenRequested ? "Confirmar" : "Guardar cambios" }</PrimaryButton>
        </FooterToolbar>
      </div>
    </div>
  );
};

export default UserUpdateEmail;