import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Suscripcion = () => {

  const [selectedId, setSelectedId] = useState(null);

  const handleClick = (id) => {
    setSelectedId(id);
  };

  return (
    <div className="contenedor">
      <div className="titulin">
        <Link to={-1}>
          <span className="simbologran">&lt;</span>
        </Link>
        <h2 className="cuerpotexto">Suscripciones</h2>      
      </div>
         
      <div className="selector rectangulo round mb20 fondotres">
        <div className={selectedId === 'mensual' ? 'selector bordenaranja rectangulo round fondoinicio bordenaranja' : 'selector rectangulo round fondoinicio'} id="mensual" onClick={() => handleClick('mensual')}>
          <h1 className='titulo textoblanco'>Mensual</h1>
          <h2 className="naranja">El primer mes gratis</h2>
          <br />
          <p>Después, 7.99 €/mes</p>
        </div>

        <div className={selectedId === 'trimestral' ? 'selector bordenaranja rectangulo round fondoinicio bordenaranja' : 'selector rectangulo round fondoinicio'} id="trimestral" onClick={() => handleClick('trimestral')}>
          <h1 className='titulo textoblanco'>Trimestral</h1>
          <h2 className="naranja">El primer mes gratis</h2>
          <div className='pastilla fondonaranja textooscuro negrita cuerpotexto'>Ahorra 10%</div>
          <p>Después, 18.89 €/cada 3 meses</p>
        </div>
        
        <div className={selectedId === 'anual' ? 'selector bordenaranja rectangulo round fondoinicio bordenaranja' : 'selector rectangulo round fondoinicio'} id="anual" onClick={() => handleClick('anual')}>
          <h2 className='titulo textoblanco'>Anual</h2>
          <div className='pastilla fondonaranja textooscuro negrita cuerpotexto w10 derecha'>Ahorra 20%</div>
          <h2 className="naranja auto">El primer mes gratis</h2>
          <p>Después, 66.89 €/al año</p>
        </div>
      </div>

      
      <form action="suscripcion1">
        <div className="contenedorabajo">
        <input type='hidden' name="tiposuscripcion" id="tiposuscripcion" value={selectedId} 
        onChange={(e) => setSelectedId(e.target.value)} />
        <div className="w100">
          <button className="w100 submit fondonaranja" type="submit">Seleccionar</button>
        </div>
        </div>
      </form>
      

    </div>
  );
};

export default Suscripcion;